export enum ApiKeyFields {
  acceptConditions = "acceptConditions",
  schedules_at_from = "schedules_at_from",
  distribution_contract_ends = "distribution_contract_ends",
  schedules_at_to = "schedules_at_to",
  artists = "artists",
  actions = "actions",
  agency = "agency",
  affiliation = "affiliation",
  iswc = "iswc",
  work = "work",
  release_track_op_isrcs = "release_track_op_isrcs",
  acceptedFiles = "acceptedFiles",
  advanceCurrency = "advanceCurrency",
  administratorName = "administratorName",
  agreementExpiresAt = "agreementExpiresAt",
  annualRevenue = "annualRevenue",
  annualRevenueCurrency = "annualRevenueCurrency",
  rightsAdministrator = "rightsAdministrator",
  scheduleEarlier = "scheduleEarlier",
  numReleaseTrackArtists = "numReleaseTrackArtists",
  artistName = "artistName",
  numAvailableReleases = "numAvailableReleases",
  numReleases = "numReleases",
  releaseDate = "releaseDate",
  releaseCounters = "releaseCounters",
  releaseDateCounters = "releaseDateCounters",
  bio = "bio",
  isMedley = "isMedley",
  isCover = "isCover",
  opUpc = "opUpc",
  op_upc = "op_upc",
  op_upcs = "op_upcs",
  opStatus = "opStatus",
  op_status = "op_status",
  release_track_title = "release_track_title",
  numReleaseTracks = "numReleaseTracks",
  // date_confirmation = "date_confirmation",
  isFocus = "isFocus",
  appleUrl = "appleUrl",
  spotifyUrl = "spotifyUrl",
  isFeatured = "isFeatured",
  releaseArtistId = "releaseArtistId",
  releaseTrackId = "releaseTrackId",
  releaseWorkId = "releaseWorkId",
  releasePublisherId = "releasePublisherId",
  releaseSongwriterId = "releaseSongwriterId",
  releaseTrackArtistId = "releaseTrackArtistId",
  releaseContributorId = "releaseContributorId",
  releaseWork = "releaseWork",
  releaseId = "releaseId",
  release_id = "release_id",
  opIsrc = "opIsrc",
  audio_blob_Path = "audio_blob_Path",
  marketing_info = "marketing_info",
  blobFieldSasUrl = "blobFieldSasUrl",
  blobFieldName = "blobFieldName",
  blob_field_name = "blob_field_name",
  contactName = "contactName",
  coveredSongs = "coveredSongs",
  notifications = "notifications",
  schedulesAt = "schedulesAt",
  previewStartsAt = "previewStartsAt",
  preview_starts_at = "preview_starts_at",
  audio_blob_path = "audio_blob_path",
  createdAt = "createdAt",
  distribution_tier = "distribution_tier",
  distributionTier = "distributionTier",
  distributionType = "distributionType",
  numNotifications = "numNotifications",
  email = "email",
  aiCoverArt = "aiCoverArt",
  coverArtBlobPath = "coverArtBlobPath",
  facebookUrl = "facebookUrl",
  firstName = "firstName",
  hasAdministrator = "hasAdministrator",
  hasMultipleChurches = "hasMultipleChurches",
  haveCoveredSongs = "haveCoveredSongs",
  distributionContractId = "distributionContractId",
  name = "name",
  type = "type",
  title = "title",
  tracks = "tracks",
  works = "works",
  genre = "genre",
  sub_genre = "sub_genre",
  territory_notes = "territory_notes",
  cover_art_blob_path = "cover_art_blob_path",
  ai_cover_art = "ai_cover_art",
  date_confirmation = "date_confirmation",
  schedule_earlier = "schedule_earlier",
  additional_options = "additional_options",
  territory = "territory",
  value = "value",
  id = "id",
  artist = "artist",
  isActive = "isActive",
  declineReason = "declineReason",
  isAdmin = "isAdmin",
  isEmerging = "isEmerging",
  isInvited = "isInvited",
  instagramUrl = "instagramUrl",
  labelName = "labelName",
  formName = "formName",
  primaryFormName = "primaryFormName",
  secondaryFormName = "secondaryFormName",
  tertiaryFormName = "tertiaryFormName",
  formId = "formId",
  primaryFormId = "primaryFormId",
  secondaryFormId = "secondaryFormId",
  tertiaryFormId = "tertiaryFormId",
  blob_action = "blob_action",
  blob_extension = "blob_extension",
  labelWebsite = "labelWebsite",
  isRevisedForm = "isRevisedForm",
  language = "language",
  lyrics = "lyrics",
  audioBlobPath = "audioBlobPath",
  audioBlobRelationIds = "audioBlobRelationIds",
  versionTitle = "versionTitle",
  version_title = "version_title",
  aiAudio = "aiAudio",
  ai_audio = "ai_audio",
  comment = "comment",
  fieldType = "fieldType",
  user = "user",
  user_id = "user_id",
  lastName = "lastName",
  afterId = "afterId",
  commentFieldName = "commentFieldName",
  legal_first_name = "legal_first_name",
  numReleaseGeneralArtists = "numReleaseGeneralArtists",
  releaseGeneralArtists = "releaseGeneralArtists",
  numRevisedForms = "numRevisedForms",
  legal_last_name = "legal_last_name",
  artist_name = "artist_name",
  songwriter_name = "songwriter_name",
  tiers = "tiers",
  user_email = "user_email",
  revisedForms = "revisedForms",
  revisedForm = "revisedForm",
  revised_form = "revised_form",
  business_address_1 = "business_address_1",
  business_address_2 = "business_address_2",
  edits = "edits",
  city = "city",
  state = "state",
  zip_code = "zip_code",
  country = "country",
  comments = "comments",
  numCommentsForUser = "numCommentsForUser",
  numCommentsForAdmin = "numCommentsForAdmin",
  album_names = "album_names",
  split = "split",
  eps = "eps",
  forAdmin = "forAdmin",
  field_name = "field_name",
  blobExtension = "blobExtension",
  fieldName = "fieldName",
  text = "text",
  singles = "singles",
  statuses = "statuses",
  op_statuses = "op_statuses",
  messageForTheIntegrityTeam = "messageForTheIntegrityTeam",
  phone = "phone",
  publishingTier = "publishingTier",
  publishingType = "publishingType",
  qualifiedAt = "qualifiedAt",
  qualifiedType = "qualifiedType",
  receiveInfo = "receiveInfo",
  recordingLanguage = "recordingLanguage",
  referredBy = "referredBy",
  residenceCountry = "residenceCountry",
  role = "role",
  taxFormFilePath = "taxFormFilePath",
  paymentFormFilePath = "paymentFormFilePath",
  legalName = "legalName",
  signerName = "signerName",
  songwriterName = "songwriterName",
  spotifyArtistId = "spotifyArtistId",
  spotifyArtistUrl = "spotifyArtistUrl",
  spotifyArtistFollowers = "spotifyArtistFollowers",
  status = "status",
  schedules_at = "schedules_at",
  user_distribution_tiers = "user_distribution_tiers",
  tiktokUrl = "tiktokUrl",
  contractType = "contractType",
  twitterHandle = "twitterHandle",
  updatedAt = "updatedAt",
  usedSongs = "usedSongs",
  csId = "csId",
  csEndsAt = "csEndsAt",
  csFileId = "csFileId",
  csSigned = "csSigned",
  csStartsAt = "csStartsAt",
  csWarningAt = "csWarningAt",
  user_is_admin = "user_is_admin",
  user_is_active = "user_is_active",
  user_is_invited = "user_is_invited",
  user_distribution_type_is_set = "user_distribution_type_is_set",
  user_publishing_type_is_set = "user_publishing_type_is_set",
  user_statuses = "user_statuses",
  user_qualified_at_from = "user_qualified_at_from",
  user_qualified_at_to = "user_qualified_at_to",
  user_created_at_from = "user_created_at_from",
  distribution_contract_cs_ends_at_from = "distribution_contract_cs_ends_at_from",
  distribution_contract_cs_ends_at_to = "distribution_contract_cs_ends_at_to",
  user_created_at_to = "user_created_at_to",
  user_order_by_field_name = "user_order_by_field_name",
  user_order = "user_order",
  artist_order_by_field_name = "artist_order_by_field_name",
  artist_order = "artist_order",
  user_offset = "user_offset",
  release_general_artist_offset = "release_general_artist_offset",
  release_general_artist_limit = "release_general_artist_limit",
  release_general_artist_artist_name = "release_general_artist_artist_name",
  user_limit = "user_limit",
  distribution_contract_order_by_field_name = "distribution_contract_order_by_field_name",
  distribution_contract_order = "distribution_contract_order",
  offset = "offset",
  limit = "limit",
  userId = "userId",
  royalty_rate = "royalty_rate",
  contract_term = "contract_term",
  marketing_service = "marketing_service",
  advance = "advance",
  advance_currency = "advance_currency",
  advance_detail = "advance_detail",
  contract_type = "contract_type",
  reviewer_name = "reviewer_name",
  websiteUrl = "websiteUrl",
  specialProvisions = "specialProvisions",
  special_provisions = "special_provisions",
  youtubeChannelId = "youtubeChannelId",
  contractRenewal = "contractRenewal",
  contract_renewal = "contract_renewal",
  youtubeChannelUrl = "youtubeChannelUrl",
  youtubeChannelSubscribers = "youtubeChannelSubscribers",
  distributionStatus = "distributionStatus",
  publishingStatus = "publishingStatus",
  settings = "settings",
  signer_name = "signer_name",
  legal_name = "legal_name",
  tax_form_blob_path = "tax_form_blob_path",
  payment_form_blob_path = "payment_form_blob_path",
  form_confirmation = "form_confirmation",
  copy = "copy",
  // infoConfirmation = "infoConfirmation",
  info_confirmation = "info_confirmation",
  template = "template",
  templateFields = "templateFields",
  distributionContractSasUrl = "distributionContractSasUrl",
  spotifyMonthlyListeners = "spotifyMonthlyListeners",
  isExisting = "isExisting",
  distributionContractCountry = "distributionContractCountry",
}
