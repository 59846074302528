import { EmergingIcon, EstablishedIcon } from "assets/icons/14px";
import { Thresholds } from "types";
import { DistributionTier } from "types";

import { ReleaseSlotThresholds } from "./types";

export const defaultValues: Record<ReleaseSlotThresholds, number> = {
  [Thresholds.RELEASE_DATE_ENTRY_MAX_NUM]: 10,
  [Thresholds.RELEASE_DATE_EMERGING_MAX_NUM]: 10,
  [Thresholds.RELEASE_DATE_ESTABLISHED_MAX_NUM]: 10,
};

export const THRESHOLDS_DATA = [
  { label: "Entry", icon: null, fieldName: Thresholds.RELEASE_DATE_ENTRY_MAX_NUM, tier: DistributionTier.Entry },
  {
    label: "Emerging",
    icon: <EmergingIcon />,
    fieldName: Thresholds.RELEASE_DATE_EMERGING_MAX_NUM,
    tier: DistributionTier.Emerging,
  },
  {
    label: "Established",
    icon: <EstablishedIcon />,
    fieldName: Thresholds.RELEASE_DATE_ESTABLISHED_MAX_NUM,
    tier: DistributionTier.Established,
  },
];
