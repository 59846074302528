export const TERRITORY_LABEL_VALUE_OPTIONS = [
  { label: "United States", value: "US" },
  { label: "United Kingdom", value: "GB" },
  { label: "Afghanistan", value: "AF" },
  { label: "Åland Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua And Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "BQ" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cape Verde", value: "CV" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo, The Democratic Republic Of The", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Côte d'Ivoire", value: "CI" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Curacao", value: "CW" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (Malvinas)", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "France Saint-Barthelemy", value: "BL" },
  { label: "France Saint-martin", value: "MF" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernesey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and Mcdonald Islands", value: "HM" },
  { label: "Holy See (Vatican City State)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran, Islamic Republic Of", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea, Democratic People's Republic Of (North Korea)", value: "KP" },
  { label: "Korea, Republic Of (South Korea)", value: "KR" },
  { label: "Kosovo", value: "XK" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People's Democratic Republic", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libyan Arab Jamahiriya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia, Federated States Of", value: "FM" },
  { label: "Moldova, Republic Of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "North Macedonia", value: "MK" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestinian Territory, Occupied", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "Rwanda", value: "RW" },
  { label: "Saint Helena", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent And The Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "São Tomé and Príncipe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Sint Maarten", value: "SX" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "South Sudan", value: "SS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan, Province Of China", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic Of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Vietnam", value: "VN" },
  { label: "Virgin Islands, British", value: "VG" },
  { label: "Virgin Islands, U.S.", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];

export const TERRITORY_WITH_CODE_OPTIONS = TERRITORY_LABEL_VALUE_OPTIONS.map(({ label, value }) => ({
  value,
  label: `${label} (${value})`,
}));

export const TERRITORY_VALUES = TERRITORY_LABEL_VALUE_OPTIONS.map(({ value }) => value);

interface SubGenres {
  [key: string]: string;
}

interface Genre {
  value: string;
  sub_genres?: SubGenres;
}

interface Genres {
  [key: string]: Genre;
}

export const MUSIC_GENRES: Genres = {
  ALTERNATIVE: {
    value: "Alternative",
    sub_genres: {
      INDIE_ROCK: "Indie Rock",
      POP_ROCK: "Pop/Rock",
      SINGER_SONGWRITER: "Singer/Songwriter",
    },
  },
  AUDIOBOOKS: {
    value: "Audiobooks",
  },
  BLUES: {
    value: "Blues",
  },
  CHILDREN_MUSIC: {
    value: `Children's Music`,
  },
  CHINESE: {
    value: "Chinese",
  },
  CLASSICAL: {
    value: "Classical",
  },
  CHRISTIAN_AND_GOSPEL: {
    value: "Christian & Gospel",
    sub_genres: {
      CHRISTMAS: "Christmas",
      DANCE: "Dance",
      WORSHIP: "Worship",
      SPOKEN_WORD: "Spoken Word",
      SOUNDTRACK: "Soundtrack",
      SINGER_SONGWRITER: "Singer/Songwriter",
      R_AND_B: "R&B",
      ROCK: "Rock",
      POP: "Pop",
      HIP_HOP: "Hip Hop",
      CHILDREN_MUSIC: "Children's Music",
      GOSPEL: "Gospel",
      PRAISE_AND_WORSHIP: "Praise & Worship",
    },
  },
  COMEDY: {
    value: "Comedy",
  },
  COUNTRY: {
    value: "Country",
  },
  DANCE: {
    value: "Dance",
  },
  DOCUMENTARY: {
    value: "Documentary",
  },
  ELECTRONIC: {
    value: "Electronic",
  },
  FOLK: {
    value: "Folk",
  },
  HIP_HOP_RAP: {
    value: "Hip Hop/Rap",
  },
  HOLIDAY: {
    value: "Holiday",
    sub_genres: {
      CHRISTMAS: "Christmas",
      CHRISTMAS_CHILDREN: `Christmas: Children's`,
      CHRISTMAS_RELIGIOUS: `Christmas: Religious`,
    },
  },
  INSPIRATIONAL: {
    value: "Inspirational",
    sub_genres: {
      INSPIRATIONAL: "Inspirational",
      MODERN_WORSHIP: "Modern Worship",
      PRAISE_AND_WORSHIP: "Praise & Worship",
      PUNK_CHRISTIAN: "Punk (Christian)",
      CHRISTIAN: "Christian",
      BLACK_GOSPEL: "Black Gospel",
      GOSPEL: "Gospel",
      SOUTHERN_GOSPEL: "Southern Gospel",
      FAITH_AND_SPIRITUALITY: "Faith & Spirituality",
      CONTEMPORARY_CHRISTIAN: "Contemporary Christian",
      CHRISTIAN_ROCK: "Christian Rock",
      SPIRITUAL: "Spiritual",
      CHORAL: "Choral",
      CHANTS_SACRED: "Chants/Sacred",
      CHRISTIAN_AND_GOSPEL: "Christian & Gospel",
    },
  },
  LATIN: {
    value: "Latin",
  },
  NEW_AGE: {
    value: "New Age",
  },
  OPERA: {
    value: "Opera",
  },
  POP: {
    value: "Pop",
  },
  R_AND_B_SOUL: {
    value: "R&B/Soul",
  },
  REGGAE: {
    value: "Reggae",
  },
  ROCK: {
    value: "Rock",
  },
  SOUNDTRACK: {
    value: "Soundtrack",
  },
  SPOKEN_WORD: {
    value: "Spoken Word",
  },
  VOCAL: {
    value: "Vocal",
  },
  WORLD: {
    value: "World",
    sub_genres: {
      LATINO: "Latino",
      ISRAELI: "Israeli",
    },
  },
};

export const MUSIC_GENRE_OPTIONS: LabelValue[] = Object.entries(MUSIC_GENRES).map(([value, { value: label }]) => ({
  label,
  value,
}));

export const RELEASE_ROLES_OPTIONS = [
  { label: "Vocals", value: "VOCALS" },
  { label: "Guitar", value: "GUITAR" },
  { label: "Keyboards", value: "KEYBOARDS" },
  { label: "Piano", value: "PIANO" },
  { label: "Bass", value: "BASS" },
  { label: "Drums", value: "DRUMS" },
  { label: "Mandolin", value: "MANDOLIN" },
  { label: "Producer", value: "PRODUCER" },
  { label: "Engineer", value: "ENGINEER" },
  { label: "Mixer", value: "MIXER" },
  { label: "Engineer & Mixer", value: "ENGINEER_AND_MIXER" },
  { label: "Cello", value: "CELLO" },
  { label: "Violin", value: "VIOLIN" },
  { label: "Viola", value: "VIOLA" },
  { label: "Percussion", value: "PERCUSSION" },
  { label: "Trumpet", value: "TRUMPET" },
  { label: "Saxophone", value: "SAXOPHONE" },
  { label: "Masterer", value: "MASTERER" },
  { label: "Organ", value: "ORGAN" },
  { label: "Banjo", value: "BANJO" },
  { label: "Programmer", value: "PROGRAMMER" },
  { label: "All Instruments", value: "ALL_INSTRUMENTS" },
];
