import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { FormHelperTextContainerStyled, Modal } from "components";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { ROUTES } from "constants/routes";
import { revisedFormService } from "services";
import { CommentStatus, PrimaryFormId, PrimaryFormNames } from "types";

import { handleReleaseError } from "./helpers";
import { ReleaseForm } from "./types";

const ReviewReleaseModal: FC<BaseModalProps> = ({ closeModal }) => {
  const {
    revisedFormStore: {
      comments: { primaryComments, secondaryComments },
      releaseComments: { commentsTotal },
    },
    uiStore: { resetLoading, setOverlayLoading },
  } = useStores();
  const { handleSubmit } = useFormContext<ReleaseForm>();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const hasActiveComment = useMemo(
    () => [...primaryComments, ...secondaryComments].some(({ status, forAdmin }) => status === CommentStatus.ACTIVE && !forAdmin),
    [primaryComments, secondaryComments],
  );

  const isAllCommentsResolved = commentsTotal.numCommentsForUser === 0 && !hasActiveComment;

  const onSubmit = handleSubmit(async () => {
    if (!releaseId) return;
    try {
      setOverlayLoading(true, isAllCommentsResolved ? "Validating the release…" : "Returning the release…");
      const requestParams: PrimaryFormId = {
        userId,
        primaryFormId: releaseId,
        primaryFormName: PrimaryFormNames.Releases,
      };
      if (isAllCommentsResolved) {
        await revisedFormService.approveForm(requestParams);
        enqueueSnackbar("The release has been successfully approved.");
      } else {
        await revisedFormService.returnForm(requestParams);
      }
      closeModal();
      navigate(ROUTES.RELEASES);
    } catch (error) {
      handleReleaseError(error as AxiosApiError, enqueueSnackbar);
    } finally {
      resetLoading();
    }
  });

  return (
    <>
      <Modal.ModalTitle title="Review the Release" closeModal={closeModal} />
      <Modal.ModalContent>
        <FormHelperTextContainerStyled>
          {isAllCommentsResolved ? (
            <>
              <Typography variant="body1">The release form has no unresolved client comments.</Typography>
              <Typography variant="body1">Do you want to approve it and send it to OpenPlay?</Typography>
            </>
          ) : (
            <>
              <Typography variant="body1">
                The release form contains at least 1 unresolved client comment and cannot be approved.
              </Typography>
              <Typography variant="body1">Do you want to return it to the client for review?</Typography>
            </>
          )}
        </FormHelperTextContainerStyled>
      </Modal.ModalContent>
      <Modal.ModalActions>
        <Button size="small" variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="small" variant="contained" onClick={onSubmit}>
          {isAllCommentsResolved ? "Approve" : "Return"}
        </Button>
      </Modal.ModalActions>
    </>
  );
};

export default observer(ReviewReleaseModal);
