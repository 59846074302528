export function validateCoverArtImage(files: File[]): Promise<{ valid: boolean; error: string | null }> {
  return new Promise<{ valid: boolean; error: string | null }>((resolve) => {
    const file = files[0];

    if (!file) {
      resolve({
        valid: false,
        error: "No file was uploaded.",
      });
      return;
    }

    if (file.name.toLowerCase().endsWith(".jpeg")) {
      resolve({
        valid: false,
        error:
          "The file was not uploaded. Please correct the issue and upload the file again. Your file format must be: .PNG or .JPG.",
      });
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();

      img.onload = () => {
        const { width, height } = img;

        if (width < 1400 || height < 1400) {
          resolve({
            valid: false,
            error: "Dimensions are too small. Minimum width and height is 1400px.",
          });
          return;
        } else if (width > 6000 || height > 6000) {
          resolve({
            valid: false,
            error: "Dimensions exceed the maximum limit of 6000x6000px.",
          });
          return;
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          resolve({
            valid: false,
            error: "Unable to process the image.",
          });
          return;
        }

        ctx.drawImage(img, 0, 0);

        let isGrayscale = true;

        for (let x = 0; x < width; x += Math.floor(width / 10)) {
          for (let y = 0; y < height; y += Math.floor(height / 10)) {
            const pixelData = ctx.getImageData(x, y, 1, 1).data;
            const r = pixelData[0];
            const g = pixelData[1];
            const b = pixelData[2];
            const a = pixelData[3];

            if (r !== g || g !== b) {
              isGrayscale = false;
              break;
            }
            if (a !== 255) {
              resolve({
                valid: false,
                error: "Image must have 3 channels (RGB).",
              });
              return;
            }
          }
          if (!isGrayscale) break;
        }

        if (isGrayscale) {
          resolve({
            valid: false,
            error: "Image must have 3 channels (RGB).",
          });
          return;
        }

        resolve({ valid: true, error: null });
      };

      img.onerror = () => {
        resolve({
          valid: false,
          error: "The file could not be processed. Please upload a valid image file.",
        });
      };

      img.src = reader.result as string;
    };

    reader.onerror = () => {
      resolve({
        valid: false,
        error: "The file could not be read. Please upload a valid image file.",
      });
    };

    reader.readAsDataURL(file);
  });
}
