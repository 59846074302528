import dayjs, { Dayjs } from "dayjs";
import type { ReleaseDateCounter } from "types";

// Function to determine if a given date should be disabled in the date picker
export const getDisabledDate = (date: Dayjs, slots: ReleaseDateCounter[], isAdmin?: boolean) => {
  // Define the current date and the maximum accessible date (365 days from today)
  const today = dayjs().startOf("day");
  const lastAccessibleDate = today.add(365, "day");

  // Logic for admin users
  if (isAdmin) {
    // Block dates before today or after the last accessible date
    if (date.isBefore(today, "day") || date.isAfter(lastAccessibleDate, "day")) {
      return true;
    }

    // Check if the date exists in the slots and if all slots are fully occupied
    const availableSlots = slots.find(({ releaseDate }) => dayjs(releaseDate).isSame(date, "day"))?.releaseCounters;
    if (availableSlots && availableSlots.every(({ numAvailableReleases }) => numAvailableReleases === 0)) {
      return true; // Block dates where all slots are occupied
    }

    // All conditions passed; date is available
    return false;
  }

  return !slots.find(
    ({ releaseDate, releaseCounters }) =>
      dayjs(releaseDate).isSame(date, "day") && releaseCounters.some(({ numAvailableReleases }) => numAvailableReleases > 0),
  );
};
