interface Genre {
  value: string;
  sub_genres?: {
    [key: string]: string;
  };
}

export const MUSIC_GENRES: { [key: string]: Genre } = {
  ALTERNATIVE: {
    value: "Alternative",
    sub_genres: {
      ADULT_CONTEMPORARY: "Adult Contemporary",
      INDIE_ROCK: "Indie Rock",
      POP_ROCK: "Pop/Rock",
      SINGER_SONGWRITER: "Singer/Songwriter",
    },
  },
  CHILDREN_MUSIC: {
    value: `Children's Music`,
    sub_genres: {
      CHRISTIAN: "Christian",
      CHRISTIAN_AND_GOSPEL: "Christian & Gospel",
      CHRISTMAS: "Christmas",
      CHRISTMAS_RELIGIOUS: "Christmas: Religious",
      CONTEMPORARY_CHRISTIAN: "Contemporary Christian",
      GOSPEL: "Gospel",
      INSTRUMENTAL: "Instrumental",
      ISRAELI: "Israeli",
      LATINO: "Latino",
      MODERN_WORSHIP: "Modern Worship",
      PIANO: "Piano",
      PRAISE_AND_WORSHIP: "Praise & Worship",
    },
  },
  CLASSICAL: {
    value: "Classical",
    sub_genres: {
      INSTRUMENTAL: "Instrumental",
      PIANO: "Piano",
    },
  },
  COUNTRY: {
    value: "Country",
    sub_genres: {
      SINGER_SONGWRITER: "Singer/Songwriter",
    },
  },
  ELECTRONIC: {
    value: "Electronic",
    sub_genres: {
      DOWNTEMPO: "Downtempo",
      HIP_HOP: "Hip Hop",
      AMBIENT: "Ambient",
    },
  },
  FOLK: {
    value: "Folk",
    sub_genres: {
      ACOUSTIC: "Acoustic",
      ADULT_CONTEMPORARY: "Adult Contemporary",
      INDIE_ROCK: "Indie Rock",
      POP_ROCK: "Pop/Rock",
      ROCK: "Rock",
      SINGER_SONGWRITER: "Singer/Songwriter",
    },
  },
  HIP_HOP_RAP: {
    value: "Hip Hop/Rap",
  },
  HOLIDAY: {
    value: "Holiday",
    sub_genres: {
      CHRISTMAS: "Christmas",
      CHRISTMAS_RELIGIOUS: `Christmas: Religious`,
    },
  },
  INSPIRATIONAL: {
    value: "Inspirational",
    sub_genres: {
      CHRISTIAN: "Christian",
      CHRISTIAN_AND_GOSPEL: "Christian & Gospel",
      CONTEMPORARY_CHRISTIAN: "Contemporary Christian",
      GOSPEL: "Gospel",
      MODERN_WORSHIP: "Modern Worship",
      PRAISE_AND_WORSHIP: "Praise & Worship",
    },
  },
  JAZZ: {
    value: "Jazz",
  },
  LATIN: {
    value: "Latin",
  },
  R_AND_B_SOUL: {
    value: "R&B/Soul",
  },
  REGGAE: {
    value: "Reggae",
    sub_genres: {
      DANCE_HALL: "Dancehall",
      SKA: "Ska",
    },
  },
  ROCK: {
    value: "Rock",
    sub_genres: {
      ALTERNATIVE_ROCK: "Alternative Rock",
      INDIE_ROCK: "Indie Rock",
      POP_ROCK: "Pop/Rock",
    },
  },
  WORLD: {
    value: "World",
    sub_genres: {
      LATINO: "Latino",
      ISRAELI: "Israeli",
    },
  },
};

export const MUSIC_GENRE_OPTIONS: LabelValue[] = Object.entries(MUSIC_GENRES).map(([value, { value: label }]) => ({
  label,
  value,
}));

export const getSubGenres = (genreKey: string): LabelValue[] => {
  const genre = MUSIC_GENRES[genreKey];

  if (!genre || !genre.sub_genres) {
    return [];
  }

  return Object.entries(genre.sub_genres).map(([subValue, subLabel]) => ({
    label: subLabel,
    value: subValue,
  }));
};
