import { FC, ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { BackBlackIcon } from "assets/icons/24px";
import { ConditionalTooltip } from "components/FormComponents";
import { RESOURCES_KEYS } from "i18n";

export const FormBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== "noMarginBottom" })<{
  noMarginBottom?: boolean;
}>(
  ({ theme, noMarginBottom }) =>
    `
  background-color: ${theme.palette.common.white};
  border-bottom: 1px solid ${theme.palette.divider};
  margin-bottom: ${noMarginBottom ? "0px" : "48px"};
  padding: 26px 40px;
  box-shadow: none;
  top: 80px;

  .MuiToolbar-root {
    padding: 0;
    min-height: fit-content;
  }

  .MuiButton-root {
    margin-right: 24px;
  }

  ${theme.breakpoints.down("md")} {
    height: auto;
    padding: 5px;

    // .MuiTypography-h1 {
    //   display: none;
    // }

    .MuiToolbar-root {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .MuiButton-contained {
      margin: 0 !important;
    }

    &::after {
      display: none;
    }
  }
`,
);

export const FormToolbarStyled = styled(Toolbar)`
  display: flex;
  gap: 16px;
`;

type FormBarContainerProps = {
  title: string;
  handleBackClick?: () => void;
  children?: ReactNode;
  backBtnTooltipCondition?: boolean;
  backBtnTooltipText?: string;
  hideBackBtn?: boolean;
  noMarginBottom?: boolean;
};

const FormBarContainer: FC<FormBarContainerProps> = ({
  title,
  handleBackClick,
  backBtnTooltipCondition,
  backBtnTooltipText,
  hideBackBtn,
  noMarginBottom,
  children,
}) => {
  const { t } = useTranslation([RESOURCES_KEYS.COMMON]);
  const navigate = useNavigate();

  const backClickHandler = handleBackClick || (() => navigate(-1));

  return (
    <FormBarStyled position="sticky" noMarginBottom={noMarginBottom}>
      <Toolbar>
        {!hideBackBtn ? (
          <ConditionalTooltip isTooltipVisible={Boolean(backBtnTooltipCondition)} title={backBtnTooltipText}>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              startIcon={<BackBlackIcon />}
              fullWidth={false}
              onClick={backClickHandler}
            >
              {t`back`}
            </Button>
          </ConditionalTooltip>
        ) : null}
        <Typography variant="h1" color="text.header" maxWidth="100%" noWrap sx={{ flex: 1 }}>
          {title}
        </Typography>
        {children}
      </Toolbar>
    </FormBarStyled>
  );
};

type FormBarProps = FormBarContainerProps & {
  onSubmit: () => void;
  submitBtnText?: string;
  saveTooltipMessage?: string;
};

const FormBar: FC<FormBarProps> = ({
  title,
  handleBackClick,
  onSubmit,
  submitBtnText = "submitApplication",
  saveTooltipMessage = "To submit the application, please complete all required fields.",
  backBtnTooltipText,
  backBtnTooltipCondition,
  hideBackBtn,
}) => {
  const {
    formState: { isValid, isDirty },
  } = useFormContext();
  const { t } = useTranslation([RESOURCES_KEYS.COMMON]);

  return (
    <FormBarContainer
      title={title}
      handleBackClick={handleBackClick}
      backBtnTooltipText={backBtnTooltipText}
      backBtnTooltipCondition={backBtnTooltipCondition}
      hideBackBtn={hideBackBtn}
    >
      <ConditionalTooltip title={saveTooltipMessage} isTooltipVisible={!isValid}>
        <Button variant="contained" size="medium" onClick={onSubmit} disabled={!isValid || !isDirty}>
          <span>{t(submitBtnText)}</span>
        </Button>
      </ConditionalTooltip>
    </FormBarContainer>
  );
};

export default FormBar;
export { FormBarContainer };
