import { FC } from "react";
import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "stores";

import { handleArtistsError } from "components/ReleaseForm/helpers";
import { AddEditArtistForm } from "components/ReleaseForm/Shared";
import { prepareRequest } from "components/ReleaseForm/Shared/Artists/AddEditArtistForm/helpers";
import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import {
  ApiKeyFields,
  PrimaryFormNames,
  ReleaseArtistsForm,
  SecondaryFormId,
  SecondaryFormNames,
  SecondaryPrimaryFormId,
} from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils";

import { AddEditArtistProps } from "./types";
import { useReleaseArtistsFormState } from "./useReleaseArtistsFormState";

const AddEditArtist: FC<AddEditArtistProps> = ({ record, successCallback, closeModal }) => {
  const formState = useReleaseArtistsFormState()(record?.id || "");
  const { enqueueSnackbar } = useSnackbar();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const {
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();

  const handlePatchRequest = async (
    formData: ReleaseArtistsForm,
    requestParams: SecondaryFormId,
    dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>,
  ) => {
    const requestData = prepareRequest(formData, dirtyFields);
    await revisedFormService.patchSecondaryForm(requestParams, requestData);
    const { revisedForm } = await revisedFormService.getSecondaryFormId(requestParams);
    successCallback?.(revisedForm);
  };

  const handlePostRequest = async (formData: ReleaseArtistsForm, requestParams: SecondaryPrimaryFormId) => {
    const { revisedForm } = await revisedFormService.postSecondaryForm(
      requestParams,
      replaceEmptyAndUndefinedWithNull({ ...formData, [ApiKeyFields.releaseId]: releaseId }),
    );
    successCallback?.(revisedForm);
  };

  const onSubmit =
    (dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>) => async (formData: ReleaseArtistsForm) => {
      try {
        setOverlayLoading(true);

        const requestParams: SecondaryPrimaryFormId = {
          userId,
          [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
          [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseArtists,
          [ApiKeyFields.primaryFormId]: releaseId!,
        };

        if (record) {
          await handlePatchRequest(formData, { ...requestParams, [ApiKeyFields.secondaryFormId]: record.id! }, dirtyFields);
        } else {
          await handlePostRequest(formData, requestParams);
        }
        closeModal();
      } catch (error) {
        handleArtistsError(error as AxiosApiError, enqueueSnackbar);
      } finally {
        resetLoading();
      }
    };

  return <AddEditArtistForm formState={formState} record={record} onSubmit={onSubmit} closeModal={closeModal} />;
};

export default observer(AddEditArtist);
