import { FC, useEffect } from "react";
import { DropzoneOptions } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import {
  CheckboxField,
  DeleteFileModal,
  DropZoneField,
  FormBlock,
  FormBlockGridGap,
  StepFlowController,
  StepFlowControllerProps,
  TextField,
} from "components";
import type { DeleteFileCallback } from "components/DeleteFileModal";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { ApiKeyFields, UserStatus } from "types";
import { FileOptionProps } from "types/revisedForms";
import { SizeUnit } from "utils";

import { DROP_FILE_TEXT, fileOptions, FORM_LABELS, HELPER_TEXT } from "./constants";
import { ElectronicPaymentFormHelperText, TaxFormHelperText } from "./HelperText";

interface DistributionInformationProps extends StepFlowControllerProps {
  fileAction: (fieldName: ApiKeyFields, options: FileOptionProps) => void;
  isDeleteAllowed?: boolean;
  isAdmin?: boolean;
}

const DistributionInformation: FC<DistributionInformationProps> = (props) => {
  const {
    uiStore: { openModal },
    qualificationStore: { formStatus },
  } = useStores();
  const { control, watch, trigger } = useFormContext();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === ApiKeyFields.form_confirmation) {
        trigger(ApiKeyFields.form_confirmation);
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  const getDefaultTextAreaProps = () => ({
    control: control,
  });

  const handleFileDelete = async (onDeleteClick: DeleteFileCallback) => {
    openModal({
      component: (modalProps) => <DeleteFileModal {...modalProps} onDeleteClick={onDeleteClick} />,
      PaperProps: { sx: { width: "448px" } },
    });
  };

  const getDefaultDropzoneProps = () => ({
    maxSizeLimit: [10, "MB"] as [number, SizeUnit],
    options: {
      multiple: false,
      ...fileOptions,
    } as DropzoneOptions,
    isDownloadAllowed: props.isAdmin,
    isDeleteAllowed: props.isDeleteAllowed,
    isDeletedState: props.isDeleteAllowed,
    fileAction: props.fileAction,
    onDeleteCallback: handleFileDelete,
  });

  const isPendingSignature = formStatus === UserStatus.DISTR_CONTRACT_GENERATED;

  return (
    <StepFlowController {...props}>
      <FormBlock>
        <Grid container gap={FormBlockGridGap}>
          <FormGridFieldItem
            fieldName={ApiKeyFields.signer_name}
            label={FORM_LABELS[ApiKeyFields.signer_name]}
            helperText={HELPER_TEXT[ApiKeyFields.signer_name]}
          >
            {(field) => <TextField {...field} {...getDefaultTextAreaProps()} />}
          </FormGridFieldItem>
          <FormGridFieldItem
            fieldName={ApiKeyFields.legal_name}
            label={FORM_LABELS[ApiKeyFields.legal_name]}
            helperText={HELPER_TEXT[ApiKeyFields.legal_name]}
          >
            {(field) => <TextField {...field} {...getDefaultTextAreaProps()} />}
          </FormGridFieldItem>
          <FormGridFieldItem
            fieldName={ApiKeyFields.tax_form_blob_path}
            label={FORM_LABELS[ApiKeyFields.tax_form_blob_path]}
            helperText={<TaxFormHelperText hideDownloadBlock={isPendingSignature || props.isAdmin} />}
          >
            {(field) => (
              <DropZoneField
                {...field}
                {...getDefaultTextAreaProps()}
                {...getDefaultDropzoneProps()}
                formFile={false}
                dropFileText={DROP_FILE_TEXT[ApiKeyFields.tax_form_blob_path]}
              />
            )}
          </FormGridFieldItem>
          <FormGridFieldItem
            fieldName={ApiKeyFields.payment_form_blob_path}
            label={FORM_LABELS[ApiKeyFields.payment_form_blob_path]}
            helperText={<ElectronicPaymentFormHelperText hideDownloadBlock={isPendingSignature || props.isAdmin} />}
          >
            {(field) => (
              <DropZoneField
                {...field}
                {...getDefaultTextAreaProps()}
                {...getDefaultDropzoneProps()}
                formFile={false}
                dropFileText={DROP_FILE_TEXT[ApiKeyFields.payment_form_blob_path]}
              />
            )}
          </FormGridFieldItem>
          <Grid item xs={12} md={12}>
            <Divider orientation="horizontal" />
          </Grid>
          <FormGridFieldItem label={""} fieldName={ApiKeyFields.form_confirmation} hideLabelGrid inputGrid={{ md: 11 }}>
            {(field) => (
              <CheckboxField
                {...field}
                {...getDefaultTextAreaProps()}
                label={FORM_LABELS[ApiKeyFields.form_confirmation]}
                isRequiredField
              />
            )}
          </FormGridFieldItem>
        </Grid>
      </FormBlock>
    </StepFlowController>
  );
};

export default observer(DistributionInformation);
