import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import dayjs from "dayjs";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import { EnqueueSnackbar } from "notistack";

import { ApiKeyFields, DigitalRelease } from "types";
import { convertSnakeToCamel, replaceEmptyAndUndefinedWithNull } from "utils";

export const prepareReleaseFormRequest = (
  formData: DigitalRelease,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>,
) => {
  const omitData = omit(formData, [ApiKeyFields.id]);
  const filteredData = pickBy(
    {
      ...omitData,
      [ApiKeyFields.schedules_at]: formData[ApiKeyFields.schedules_at]
        ? dayjs(formData[ApiKeyFields.schedules_at]).format("YYYY-MM-DD")
        : null,
      [ApiKeyFields.territory]: Array.isArray(formData[ApiKeyFields.territory])
        ? formData[ApiKeyFields.territory].join(",")
        : null,
    },
    (_, key) => dirtyFields[key],
  );

  const checkGenreFields =
    (formData[ApiKeyFields.genre] && dirtyFields[ApiKeyFields.genre]) ||
    (formData[ApiKeyFields.genre] && dirtyFields[ApiKeyFields.sub_genre])
      ? {
          [ApiKeyFields.genre]: formData[ApiKeyFields.genre],
          [ApiKeyFields.sub_genre]: formData[ApiKeyFields.sub_genre],
        }
      : {};

  const checkReleaseFields = () => {
    if (dirtyFields[ApiKeyFields.schedule_earlier] || dirtyFields[ApiKeyFields.additional_options]) {
      if (formData[ApiKeyFields.schedule_earlier] === true) {
        if (formData[ApiKeyFields.additional_options]?.trim()) {
          return {
            [ApiKeyFields.schedule_earlier]: true,
            [ApiKeyFields.additional_options]: formData[ApiKeyFields.additional_options],
          };
        } else {
          return {
            [ApiKeyFields.schedule_earlier]: false,
            [ApiKeyFields.additional_options]: null,
          };
        }
      } else if (formData[ApiKeyFields.schedule_earlier] === false) {
        return {
          [ApiKeyFields.schedule_earlier]: false,
          [ApiKeyFields.additional_options]: null,
        };
      }
    }

    return {};
  };

  return replaceEmptyAndUndefinedWithNull(
    convertSnakeToCamel({
      ...filteredData,
      ...checkGenreFields,
      ...checkReleaseFields(),
    }),
  );
};

export const handleReleaseError = (axiosError: AxiosApiError, enqueueSnackbar: EnqueueSnackbar) => {
  if (axiosError.response?.data.errorCode === "MAX_NUM_RELEASES") {
    enqueueSnackbar("All slots for this release date have been taken. Please select another date.", {
      iconType: "warning",
    });
  }
};
