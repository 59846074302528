import Typography from "@mui/material/Typography";

import { HelpAccordion } from "components/FormComponents";

const HelpAccordionText = () => {
  return (
    <HelpAccordion title="Need some help?" position="sticky">
      <Typography variant="body1" flex={1}>
        If you’d like your release to be pitched, please be sure to submit a focus track and a marketing copy.
      </Typography>
      <br />
      <Typography variant="body1" flex={1}>
        The order in which you arrange the tracks is the order that will appear on the music streaming platforms. You can change
        the order of the tracks by dragging a row and dropping it in the place of another row.
      </Typography>
    </HelpAccordion>
  );
};

export default HelpAccordionText;
