import Typography from "@mui/material/Typography";

import { HelpAccordion } from "components/FormComponents";

const HelpAccordionText = () => {
  return (
    <HelpAccordion title="Need some help?" position="sticky">
      <Typography variant="body1" flex={1}>
        Please make sure that the cover art file you use meets the following requirements:
      </Typography>
      <ul>
        {[
          "Format: JPG, PNG",
          "Color Mode: 8-bit RGB (no CMYK, spot colors, alpha channel(s), or layers) (recommended)",
          "Dimensions: Minimum 1400px wide by 1400px high, maximum 6000px wide by 6000px high",
          "Aspect ratio: 1:1 (recommended)",
          "Resolution: 300dpi (recommended)",
          "Color Profile: Color space and embedded color profile must match (recommended)",
        ].map((text) => (
          <li key={text}>
            <Typography variant="body1">{text}</Typography>
          </li>
        ))}
      </ul>
    </HelpAccordion>
  );
};

export default HelpAccordionText;
