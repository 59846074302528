import { ApiKeyFields } from "types";

export const defaultValues = {
  [ApiKeyFields.name]: "",
  [ApiKeyFields.advance]: null,
  [ApiKeyFields.email]: null,
  [ApiKeyFields.rightsAdministrator]: "",
  [ApiKeyFields.affiliation]: "",
  [ApiKeyFields.country]: "",
  [ApiKeyFields.split]: null,
};

export const HELPER_LINKS = {
  CCLI: "https://songselect.ccli.com/",
  ASCAP: "https://www.ascap.com/repertory#/",
  BMI: "https://repertoire.bmi.com/",
  MLC: "https://portal.themlc.com/search#work",
};

export const HELPERS_LABELS: { [key: string]: string } = {
  [ApiKeyFields.rightsAdministrator]: "The person or entity that manages and registers your songs with the PROs, CCLI, etc.",
  [ApiKeyFields.affiliation]: `Performing Rights Organizations (PROs) are responsible for collecting income on behalf of the publisher when a song is publicly performed or broadcasted. A Publisher's affiliation with a PRO is dictated by the PRO the songwriter share is affiliated with. Common agencies are ASCAP, BMI, SESAC. Please check with the songwriter if you’re unsure.`,
};
