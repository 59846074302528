import * as zod from "zod";

import { MAX_INPUT_LENGTH_ERR_MSG, MAX_LENGTH_INPUT, MIN_LEN_ERR_MSG, MIN_LEN_ERR_TEXT } from "constants/validation";
import { ApiKeyFields } from "types";

export const titleCoverArtSchema = zod.object({
  [ApiKeyFields.title]: zod
    .string({ invalid_type_error: MIN_LEN_ERR_TEXT })
    .trim()
    .min(1, MIN_LEN_ERR_MSG)
    .max(MAX_LENGTH_INPUT, MAX_INPUT_LENGTH_ERR_MSG),
  [ApiKeyFields.version_title]: zod
    .string({ invalid_type_error: MIN_LEN_ERR_TEXT })
    .trim()
    .max(MAX_LENGTH_INPUT, MAX_INPUT_LENGTH_ERR_MSG)
    .nullable()
    .optional(),
  [ApiKeyFields.cover_art_blob_path]: zod.string(),
  [ApiKeyFields.ai_cover_art]: zod.boolean().nullable().optional(),
});
