import { BlockBlobClient } from "@azure/storage-blob";

import { revisedFormService } from "services";
import { ApiKeyFields, PrimaryFormNames } from "types";

import { SasUrl } from "./types";

interface retrieveCoverArtProps {
  primaryFormId: string;
  coverArtBlobPath: string | null;
  userId: string | null;
}

function getFileExtension(url: string): string | null {
  const match = url.match(/\.(jpg|png)$/);
  return match ? match[0] : null;
}

export const useCoverArt = () => {
  const retrieveCoverArt = async (
    { primaryFormId, userId, coverArtBlobPath }: retrieveCoverArtProps,
    abortSignal: AbortSignal,
    sasUrlKey?: keyof SasUrl,
  ): Promise<string | null> => {
    try {
      const blobResponse = await revisedFormService.getPrimaryBlob({
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        primaryFormId,
        field_name: ApiKeyFields.cover_art_blob_path,
        params: { blob_action: "get", blob_extension: getFileExtension(coverArtBlobPath!) || ".jpg" },
      });

      const blobUrl = sasUrlKey ? blobResponse.sasUrls?.[sasUrlKey] : blobResponse[ApiKeyFields.blobFieldSasUrl];

      if (!blobUrl) {
        throw new Error("No valid SAS URL found");
      }

      const blobClient = new BlockBlobClient(blobUrl);
      const response = await blobClient.download(void 0, void 0, { abortSignal });
      const blob = await response.blobBody;
      if (!blob) {
        throw new Error("Failed to download blob");
      }
      const filePath = window.URL.createObjectURL(blob);
      return filePath;
    } catch {
      return null;
    }
  };

  return { retrieveCoverArt };
};
