import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

import { ArtistInfoBlueIcon } from "assets/icons/24px";
import { FormBlock, FormBlockGridGap } from "components/FormComponents";
import { FormGridItem } from "components/FormComponents/FormGridItems";
import FormLabelDivider from "components/FormComponents/FormLabelDivider";
import { NumberInputField, SelectField, TextAreaField, TextField } from "components/HookFormControls";
import { RECORDING_LANGUAGES } from "constants/options";
import { RESOURCES_KEYS } from "i18n";
import { ApiKeyFields } from "types";

import { FORM_LABELS, HELPER_TEXT, MUSIC_SERVICES_FIELD_ICONS, MUSIC_SERVICES_FIELDS } from "../constants";
import SocialMediaBlock from "../SocialMediaBlock";

import FollowersHelper from "./FollowersHelper";

type ArtistInformation = {
  publishing?: boolean;
  readOnly?: boolean;
};

const ArtistInformation: FC<ArtistInformation> = ({ publishing, readOnly }) => {
  const { control } = useFormContext();
  const { t } = useTranslation([RESOURCES_KEYS.QUALIFICATION]);

  const defaultFieldFormProps = (fieldName: ApiKeyFields) => ({
    fieldName,
    control: control,
    readOnly,
  });

  return (
    <FormBlock title={publishing ? t`songwriterInfo.sectionTitle` : t`artistInfo.sectionTitle`} icon={<ArtistInfoBlueIcon />}>
      <Grid container gap={FormBlockGridGap}>
        <Grid item xs={12}>
          <FormLabelDivider title={publishing ? t`songwriterInfo.about` : t`artistInfo.about`} />
        </Grid>
        <FormGridItem label={t(FORM_LABELS[ApiKeyFields.bio])}>
          <TextAreaField {...defaultFieldFormProps(ApiKeyFields.bio)} rows={5} inputProps={{}} maxLength={5000} />
        </FormGridItem>
        <FormGridItem
          label={t(FORM_LABELS[ApiKeyFields.referredBy])}
          helperText={t(HELPER_TEXT[ApiKeyFields.referredBy])}
          isOptionalField
        >
          <TextField {...defaultFieldFormProps(ApiKeyFields.referredBy)} />
        </FormGridItem>
        <FormGridItem
          label={publishing ? t`songwriterInfo.websiteOptional` : t(FORM_LABELS[ApiKeyFields.websiteUrl])}
          helperText={HELPER_TEXT[ApiKeyFields.websiteUrl]}
          isOptionalField
        >
          <TextField {...defaultFieldFormProps(ApiKeyFields.websiteUrl)} />
        </FormGridItem>
        {!publishing && (
          <>
            <Grid item xs={12}>
              <Grid container gap={FormBlockGridGap}>
                <Grid item xs={12}>
                  <FormLabelDivider title={t(`artistInfo.recordingInformationTitle`)} />
                </Grid>
                <FormGridItem
                  label={t(FORM_LABELS[ApiKeyFields.artistName])}
                  helperText={t(HELPER_TEXT[ApiKeyFields.artistName])}
                >
                  <TextField {...defaultFieldFormProps(ApiKeyFields.artistName)} />
                </FormGridItem>
                <FormGridItem
                  label={t(FORM_LABELS[ApiKeyFields.recordingLanguage])}
                  helperText={t(HELPER_TEXT[ApiKeyFields.recordingLanguage])}
                >
                  <SelectField {...defaultFieldFormProps(ApiKeyFields.recordingLanguage)} options={RECORDING_LANGUAGES} />
                </FormGridItem>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container gap={FormBlockGridGap}>
                <Grid item xs={12}>
                  <FormLabelDivider title={t(`artistInfo.linksToMusicTitle`)} />
                </Grid>
                <FormGridItem
                  label={t(FORM_LABELS[ApiKeyFields.spotifyMonthlyListeners])}
                  helperText={t(HELPER_TEXT[ApiKeyFields.spotifyMonthlyListeners])}
                >
                  <NumberInputField {...defaultFieldFormProps(ApiKeyFields.spotifyMonthlyListeners)} maxLength={15} />
                </FormGridItem>
                {MUSIC_SERVICES_FIELDS.map((fieldName) => {
                  const IconComponent = MUSIC_SERVICES_FIELD_ICONS[fieldName];
                  return (
                    <FormGridItem
                      label={t(FORM_LABELS[fieldName])}
                      helperText={
                        readOnly ? (
                          <FollowersHelper fieldName={fieldName} />
                        ) : (
                          <Trans
                            t={t}
                            i18nKey={HELPER_TEXT[fieldName]}
                            components={{
                              linkHref: (
                                <a
                                  href="https://support.google.com/youtube/answer/3250431?hl=en"
                                  rel="noreferrer"
                                  target="_blank"
                                />
                              ),
                            }}
                          />
                        )
                      }
                      key={fieldName}
                    >
                      <TextField
                        {...defaultFieldFormProps(fieldName)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">{IconComponent ? <IconComponent /> : null}</InputAdornment>
                          ),
                        }}
                      />
                    </FormGridItem>
                  );
                })}
              </Grid>
            </Grid>
            <SocialMediaBlock readOnly={readOnly} />
          </>
        )}
      </Grid>
    </FormBlock>
  );
};

export default ArtistInformation;
