import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { ReleaseDateProvider } from "context";
import { Dayjs } from "dayjs";
import type { ReleaseDateCounter } from "types";

import { DatePickerField } from "components";
import ReleaseSlotsTooltip from "components/ReleaseForm/Shared/ReleaseSlotsTooltip/ReleaseSlotsTooltip";
import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { EN_DATE_FORMAT } from "constants/options";
import { releaseDateCounterService } from "services";
import { ApiKeyFields } from "types";

import { getDisabledDate } from "./helpers";

interface DatePickerProps {
  fieldName: ApiKeyFields;
  readOnly?: boolean;
  isAdmin?: boolean;
}

const DatePickerWrapperStyled = styled.span`
  width: 100%;
  * > .ant-picker-cell-disabled {
    pointer-events: initial !important;
  }
`;

const DatePicker: FC<DatePickerProps> = ({ isAdmin, ...field }) => {
  const { control } = useFormContext();
  const { userId = void 0 } = useParams<ReleaseUrlParams>();
  const [slots, setSlots] = useState<ReleaseDateCounter[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const retrieveDateCounters = async (userId?: string) => {
    try {
      setLoading(true);
      const { releaseDateCounters } = await releaseDateCounterService.getReleaseDateCounter(userId);
      setSlots(releaseDateCounters);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    retrieveDateCounters(userId);
  }, [userId]);

  return (
    <ReleaseDateProvider slots={slots}>
      <DatePickerWrapperStyled>
        <DatePickerField
          {...field}
          control={control}
          disabledDate={(date) => getDisabledDate(date, slots, isAdmin)}
          format={EN_DATE_FORMAT}
          placeholder={EN_DATE_FORMAT}
          disabled={loading}
          cellRender={(date, info) => (
            <ReleaseSlotsTooltip date={date as Dayjs} isAdmin={isAdmin}>
              {info.originNode}
            </ReleaseSlotsTooltip>
          )}
        />
      </DatePickerWrapperStyled>
    </ReleaseDateProvider>
  );
};

export default DatePicker;
