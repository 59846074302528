import * as zod from "zod";

import { MAX_LENGTH_INPUT, MIN_LEN_ERR_TEXT, MIN_LEN_NUM_ERR_TEXT } from "constants/validation";
import { ApiKeyFields } from "types";

const baseSchema = zod.object({
  [ApiKeyFields.artistName]: zod
    .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
    .trim()
    .min(1, MIN_LEN_ERR_TEXT)
    .max(MAX_LENGTH_INPUT),
  [ApiKeyFields.isFeatured]: zod.boolean().nullable(),
});

export const appleUrlSchema = zod
  .object({
    [ApiKeyFields.appleUrl]: zod
      .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
      .trim()
      .max(MAX_LENGTH_INPUT)
      .nullable()
      .optional(),
    newAppleArtist: zod.boolean().nullable(),
  })
  .superRefine((data, ctx) => {
    if (!data.newAppleArtist && !data[ApiKeyFields.appleUrl]) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        path: [ApiKeyFields.appleUrl],
        message: MIN_LEN_ERR_TEXT,
      });
    }
  });

export const spotifyUrlSchema = zod
  .object({
    [ApiKeyFields.spotifyUrl]: zod
      .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
      .trim()
      .max(MAX_LENGTH_INPUT)
      .nullable()
      .optional(),
    newSpotifyArtist: zod.boolean().nullable(),
  })
  .superRefine((data, ctx) => {
    if (!data.newSpotifyArtist && !data[ApiKeyFields.spotifyUrl]) {
      ctx.addIssue({
        code: zod.ZodIssueCode.custom,
        path: [ApiKeyFields.spotifyUrl],
        message: MIN_LEN_ERR_TEXT,
      });
    }
  });

export const schema = zod.intersection(baseSchema, zod.intersection(appleUrlSchema, spotifyUrlSchema));
