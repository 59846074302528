import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";

import msalConfig, { authority, clientId } from "constants/msalConfig";

interface AuthError extends Error {
  errorCode: string;
  errorMessage: string;
}

let cachedAccessToken: string | null = null;
let tokenExpiryTime: number | null = null;

export const _resetTokenCache = () => {
  cachedAccessToken = null;
  tokenExpiryTime = null;
};

export const _setCachedToken = (token: string, expiryTime: number): void => {
  cachedAccessToken = token;
  tokenExpiryTime = expiryTime;
};

const TOKEN_EXPIRY_BUFFER = 5 * 60 * 1000;

const isTokenExpired = (): boolean => {
  return tokenExpiryTime ? Date.now() >= tokenExpiryTime - TOKEN_EXPIRY_BUFFER : true;
};

const handleAuthError = async (error: AuthError) => {
  if (["invalid_grant", "interaction_required"].includes(error?.errorCode)) {
    _resetTokenCache();
    await msalConfig.acquireTokenRedirect({ scopes: [clientId, "openid"] });
  } else {
    _resetTokenCache();
    await msalConfig.logoutRedirect();
  }
};

export const getAccessToken = async (scopes: string[] = [clientId, "openid", "offline_access"]): Promise<string | null> => {
  const account: AccountInfo | null = msalConfig.getActiveAccount();

  if (!account) {
    throw new Error("No user account found");
  }

  if (cachedAccessToken && !isTokenExpired()) {
    return cachedAccessToken;
  }

  try {
    const accessTokenResponse: AuthenticationResult = await msalConfig.acquireTokenSilent({
      scopes: scopes,
      account: account,
      authority: `${authority}/${account.tenantId}`,
    });

    if (accessTokenResponse.expiresOn) {
      _setCachedToken(accessTokenResponse.accessToken, accessTokenResponse.expiresOn.getTime());
    } else {
      throw new Error("Token response does not contain an expiry time");
    }

    return accessTokenResponse.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      try {
        const newAccessTokenResponse: AuthenticationResult = await msalConfig.acquireTokenPopup({
          scopes: scopes,
          account: account,
          authority: `${authority}/${account.tenantId}`,
        });

        if (newAccessTokenResponse.expiresOn) {
          _setCachedToken(newAccessTokenResponse.accessToken, newAccessTokenResponse.expiresOn.getTime());
        }

        return newAccessTokenResponse.accessToken;
      } catch (popupError) {
        await handleAuthError(popupError as AuthError);
        return null;
      }
    } else {
      await handleAuthError(error as AuthError);
    }

    return null;
  }
};
