import { ApiKeyFields, User } from "types";

export enum PrimaryFormNames {
  DistributionInfos = "distribution-infos",
  DistributionLegals = "distribution-legals",
  Releases = "releases",
}

export enum SecondaryFormNames {
  ReleaseArtists = "release-artists",
  ReleaseWorks = "release-works",
  ReleaseTracks = "release-tracks",
}

export enum TertiaryFormNames {
  ReleaseSongwriters = "release-songwriters",
  ReleasePublishers = "release-publishers",
  ReleaseContributors = "release-contributors",
  ReleaseTrackArtists = "release-track-artists",
  ReleaseTrackWorks = "release-track-works",
}

export enum CommentStatus {
  ACTIVE = 1,
  RESOLVED = 2,
}

export enum DistributionAdvanceCurrency {
  USD = "USD",
  GBP = "GBP",
}

export enum DistributionContractType {
  ONE_CLICK = "ONE_CLICK",
  MANUAL = "MANUAL",
}

export enum ReleaseType {
  DIGITAL = "DIGITAL",
}

export enum ReleaseStatus {
  Draft = 1,
  InRevision = 2,
  ChangesNeeded = 3,
  Released = 4,
  TakenDown = 5,
}

export enum ReleaseOpStatus {
  Draft = 1,
  Validated = 2,
  Submitted = 3,
  Distributed = 4,
  Released = 5,
  TakenDown = 6,
  Deleted = 7,
  Retracted = 8,
  Error = 9,
}

export interface Comment {
  [ApiKeyFields.forAdmin]: boolean;
  [ApiKeyFields.fieldName]: ApiKeyFields;
  [ApiKeyFields.text]: string;
  [ApiKeyFields.status]: CommentStatus;
  [ApiKeyFields.releaseTrackId]: string | null;
  [ApiKeyFields.releaseArtistId]: string | null;
  [ApiKeyFields.releaseWorkId]: string | null;
  [ApiKeyFields.releasePublisherId]: string | null;
  [ApiKeyFields.releaseSongwriterId]: string | null;
  [ApiKeyFields.releaseTrackArtistId]: string | null;
  [ApiKeyFields.releaseContributorId]: string | null;
}

export interface CommentForm {
  [ApiKeyFields.forAdmin]: boolean;
  [ApiKeyFields.fieldName]: ApiKeyFields;
  [ApiKeyFields.text]: string;
  [ApiKeyFields.status]: CommentStatus;
}

export interface PrimaryId {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormId]: string;
}

export interface PrimaryForm {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
}

export interface FormId {
  [ApiKeyFields.primaryFormId]: string;
}

export interface PrimaryFormId {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
}

export interface TertiaryCommentList {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
  [ApiKeyFields.secondaryFormName]: SecondaryFormNames;
  [ApiKeyFields.tertiaryFormName]: TertiaryFormNames;
}

export interface SecondaryPrimaryFormId {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
  [ApiKeyFields.secondaryFormName]: SecondaryFormNames;
}

export interface TertiarySecondaryFormId {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
  [ApiKeyFields.secondaryFormName]: SecondaryFormNames;
  [ApiKeyFields.secondaryFormId]: string;
  [ApiKeyFields.tertiaryFormName]: TertiaryFormNames;
}

export interface SecondaryFormId {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.secondaryFormName]: SecondaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
  [ApiKeyFields.secondaryFormId]: string;
}

export interface TertiaryFormId {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.secondaryFormName]: SecondaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
  [ApiKeyFields.secondaryFormId]: string;
  [ApiKeyFields.tertiaryFormName]: TertiaryFormNames;
  [ApiKeyFields.tertiaryFormId]: string;
}

export type BlobAction = "get" | "update" | "delete";

export interface FileOptionProps {
  [ApiKeyFields.acceptedFiles]?: File[];
  [ApiKeyFields.blob_action]: BlobAction;
}

export interface BlobFilePatch {
  [ApiKeyFields.field_name]: ApiKeyFields;
  [ApiKeyFields.blobExtension]?: string;
}

export interface BlobFileParams {
  [ApiKeyFields.blob_action]: BlobAction;
  [ApiKeyFields.blob_extension]?: string;
}

export interface PrimaryFormIdBlobUrl {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
  [ApiKeyFields.field_name]: ApiKeyFields;
  [ApiKeyFields.isRevisedForm]?: boolean;
  params: BlobFileParams;
}

export interface SecondaryFormIdBlobUrl {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
  [ApiKeyFields.secondaryFormName]: SecondaryFormNames;
  [ApiKeyFields.secondaryFormId]: string;
  [ApiKeyFields.field_name]: ApiKeyFields;
  [ApiKeyFields.blob_action]: BlobAction;
}

export interface DeleteUserIdRevisedFormIdBlobUrl {
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
  [ApiKeyFields.field_name]: ApiKeyFields;
  [ApiKeyFields.userId]: string;
}

export interface PrimaryUser<T> {
  [ApiKeyFields.userId]: string;
  [ApiKeyFields.primaryFormName]: T;
}

export interface PrimaryUserForm {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
}

export interface CurrentRevisedFormIdComment {
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.formId]: string;
  [ApiKeyFields.commentFieldName]: string;
}

export interface PrimaryUserFormComment {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
  [ApiKeyFields.commentFieldName]: string;
}

export interface SecondaryUserFormComment {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
  [ApiKeyFields.secondaryFormName]: SecondaryFormNames;
  [ApiKeyFields.secondaryFormId]: string;
  [ApiKeyFields.commentFieldName]: string;
}

export interface TertiaryUserFormComment {
  [ApiKeyFields.userId]: string | null;
  [ApiKeyFields.primaryFormName]: PrimaryFormNames;
  [ApiKeyFields.primaryFormId]: string;
  [ApiKeyFields.secondaryFormName]: SecondaryFormNames;
  [ApiKeyFields.secondaryFormId]: string;
  [ApiKeyFields.tertiaryFormName]: TertiaryFormNames;
  [ApiKeyFields.tertiaryFormId]: string;
  [ApiKeyFields.commentFieldName]: string;
}

export interface PostComment {
  [ApiKeyFields.forAdmin]: boolean;
  [ApiKeyFields.fieldName]: ApiKeyFields;
  [ApiKeyFields.text]: string;
}

export interface PatchCurrentCommentForm {
  [ApiKeyFields.status]: CommentStatus;
}

export interface PatchPrimaryComment {
  [ApiKeyFields.forAdmin]: boolean;
  [ApiKeyFields.text]: string;
  [ApiKeyFields.status]: CommentStatus;
}

export interface Edit {
  [ApiKeyFields.id]: string;
  [ApiKeyFields.fieldName]: ApiKeyFields;
  [ApiKeyFields.fieldType]: string;
  [ApiKeyFields.isAdmin]: boolean;
  [ApiKeyFields.user]: User;
}

export interface BaseRegistration {
  [ApiKeyFields.id]?: string;
  [ApiKeyFields.legal_first_name]?: string | null;
  [ApiKeyFields.legal_last_name]?: string | null;
  [ApiKeyFields.artist_name]?: string | null;
  [ApiKeyFields.email]?: string | null;
  [ApiKeyFields.phone]?: string | null;
  [ApiKeyFields.business_address_1]?: string | null;
  [ApiKeyFields.business_address_2]?: string | null;
  [ApiKeyFields.city]?: string | null;
  [ApiKeyFields.state]?: string | null;
  [ApiKeyFields.zip_code]?: string | null;
  [ApiKeyFields.country]?: LabelValue<string> | string | null;
  [ApiKeyFields.album_names]?: string | null;
  [ApiKeyFields.eps]?: string | null;
  [ApiKeyFields.singles]?: string | null;
}

export interface BaseCommentEdit {
  [ApiKeyFields.comments]: Comment[];
  [ApiKeyFields.edits]: Edit[];
}

export interface ReleaseCommentCount {
  [ApiKeyFields.numCommentsForUser]: number;
  [ApiKeyFields.numCommentsForAdmin]: number;
}
