import * as zod from "zod";

import { MAX_LEN_TEXTAREA_ERR_MSG, MIN_LEN_ERR_TEXT, MIN_LEN_NUM_ERR_TEXT } from "constants/validation";
import { ApiKeyFields } from "types";

import { defaultSchemaFields } from "../AddWork/validation";

export const schema = zod.object({
  ...defaultSchemaFields,
  [ApiKeyFields.lyrics]: zod
    .string({ invalid_type_error: MIN_LEN_NUM_ERR_TEXT })
    .min(1, MIN_LEN_ERR_TEXT)
    .max(5000, MAX_LEN_TEXTAREA_ERR_MSG)
    .nullable()
    .optional(),
  [ApiKeyFields.isMedley]: zod.boolean().nullable().optional(),
  [ApiKeyFields.isCover]: zod.boolean().nullable().optional(),
});
