import Typography from "@mui/material/Typography";

import { HelpAccordion } from "components/FormComponents";

const HelpAccordionText = () => {
  return (
    <HelpAccordion title="Need some help?" position="sticky">
      <Typography variant="body1">
        The artist catalog consists of masters that have been previously released on DSPs (Digital Service Providers like Spotify,
        Apple, Amazon, etc). This information will be included on the agreement as 'Exhibit A' for you to review!
      </Typography>
      <ul>
        {[
          "EP (Extended Play): Think of these as releases longer than a single but shorter than an album, usually containing between four (4) and seven (7) masters, total release duration under 30 minutes.",
          "Album: This refers to a record with a minimum of eight (8) masters and lasting at least forty (40) minutes.",
          "Single: A record featuring up to three (3) masters. Track durations are all under 10 minutes, and total release duration is under 30 minutes.",
        ].map((text) => (
          <li key={text}>
            <Typography variant="body1">{text}</Typography>
          </li>
        ))}
      </ul>
      <Typography variant="body1">
        Hint: Looking at your Spotify or Apple discography should help. DSPs will automatically assign these for you on every
        release.
      </Typography>
    </HelpAccordion>
  );
};

export default HelpAccordionText;
