import { FieldNamesMarkedBoolean, FieldValues } from "react-hook-form";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";

import { ApiKeyFields, ReleaseArtistsForm } from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils/transformObjects";

import { ReleaseArtistCustomForm } from "./types";

export const prepareFormData = (record: ReleaseArtistsForm): ReleaseArtistCustomForm => {
  return {
    ...record,
    newAppleArtist: !record[ApiKeyFields.appleUrl],
    newSpotifyArtist: !record[ApiKeyFields.spotifyUrl],
  };
};

export const prepareRequest = (
  formData: ReleaseArtistsForm,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>,
) => {
  const filteredData = pickBy(formData, (_, key) => dirtyFields[key]);
  return replaceEmptyAndUndefinedWithNull(omit(filteredData, ["newAppleArtist", "newSpotifyArtist"]));
};
