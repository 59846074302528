import { FC, useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CustomFormContext } from "context";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { CheckboxField, FormBlockGridGap, TextField } from "components";
import { FormGridFieldItem, FormGridItem } from "components/FormComponents/FormGridItems";
import { ApiKeyFields } from "types";

import { ReleaseArtistCustomForm } from "../types";

import { FORM_ARTIST_CHECKBOX_LABEL, FORM_LABELS, HELPER_TEXT } from "./constants";
import { FormProps } from "./types";

const Form: FC<FormProps> = ({ isGeneralArtist }) => {
  const { control, watch, setValue } = useFormContext<ReleaseArtistCustomForm>();
  const { isAdmin, isFieldReadOnly } = useContext(CustomFormContext);

  const [newAppleArtist, newSpotifyArtist] = watch(["newAppleArtist", "newSpotifyArtist"]);

  useEffect(() => {
    newAppleArtist && setValue(ApiKeyFields.appleUrl, "", { shouldDirty: true, shouldValidate: true });
    newSpotifyArtist && setValue(ApiKeyFields.spotifyUrl, "", { shouldDirty: true, shouldValidate: true });
  }, [newAppleArtist, newSpotifyArtist]);

  const isNewAppleArtist = Boolean(newAppleArtist);
  const isNewSpotifyArtist = Boolean(newSpotifyArtist);

  const isReadOnly = isFieldReadOnly(ApiKeyFields.appleUrl);

  return (
    <Grid container gap={FormBlockGridGap}>
      <FormGridFieldItem
        fieldName={ApiKeyFields.artistName}
        label={FORM_LABELS[ApiKeyFields.artistName]}
        helperText={HELPER_TEXT[ApiKeyFields.artistName]}
      >
        {(field) => <TextField {...field} control={control} />}
      </FormGridFieldItem>
      {!isGeneralArtist ? (
        <FormGridFieldItem fieldName={ApiKeyFields.isFeatured} label={FORM_LABELS[ApiKeyFields.isFeatured]} isOptionalField>
          {(fieldName) => <CheckboxField {...fieldName} control={control} label={HELPER_TEXT[ApiKeyFields.isFeatured]} />}
        </FormGridFieldItem>
      ) : null}
      <FormGridFieldItem
        fieldName={ApiKeyFields.appleUrl}
        label={FORM_LABELS[ApiKeyFields.appleUrl]}
        helperText={HELPER_TEXT[ApiKeyFields.appleUrl]}
        isOptionalField={isNewAppleArtist}
        disabled={isNewAppleArtist}
      >
        {(field) => <TextField {...field} control={control} disabled={isNewAppleArtist} />}
      </FormGridFieldItem>
      <FormGridItem label="" isOptionalField>
        <CheckboxField
          control={control}
          fieldName="newAppleArtist"
          label={
            <Typography variant="caption" color="text.label">
              {FORM_ARTIST_CHECKBOX_LABEL}
            </Typography>
          }
          disabled={isAdmin || isReadOnly}
        />
      </FormGridItem>
      <FormGridFieldItem
        fieldName={ApiKeyFields.spotifyUrl}
        label={FORM_LABELS[ApiKeyFields.spotifyUrl]}
        helperText={HELPER_TEXT[ApiKeyFields.spotifyUrl]}
        isOptionalField={isNewSpotifyArtist}
        disabled={isNewSpotifyArtist}
      >
        {(field) => <TextField {...field} control={control} disabled={isNewSpotifyArtist} />}
      </FormGridFieldItem>
      <FormGridItem label="" isOptionalField>
        <CheckboxField
          control={control}
          fieldName="newSpotifyArtist"
          label={
            <Typography variant="caption" color="text.label">
              {FORM_ARTIST_CHECKBOX_LABEL}
            </Typography>
          }
          disabled={isAdmin || isReadOnly}
        />
      </FormGridItem>
    </Grid>
  );
};

export default Form;
