import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { AddIcon, UploadIcon } from "assets/icons/24px";
import { ConditionalTooltip, DndTableGrid, FormBlockHeaderStyled, ReleaseTableListBlock, TextAreaField } from "components";
import { LabelWithAsterisk } from "components";
import { FormBlock, FormBlockGridGap } from "components/FormComponents";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { StepFlowController, StepFlowControllerProps } from "components/FormComponents/StepFlowController";
import { ReleaseAlert, TableNoDataMessage, usePrimaryFormState } from "components/ReleaseForm";
import { ApiKeyFields, TracksForm } from "types";

import AddBulkTracks from "./AddBulkTracks";
import { getColumns } from "./columns";
import { FORM_LABELS, HELPER_TEXT, MARKETING_COPY_MAX_LENGTH, TRACKS_MAX_LIMIT } from "./constants";
import HelpAccordionText from "./HelpAccordionText";
import { BlockContainer } from "./styled";
import { useTrackNotificationParams } from "./useTrackNotificationParams";
import { useTracks } from "./useTracks";

const NoData = () => <TableNoDataMessage text="There are no track(s) added." />;

const Tracks: FC<StepFlowControllerProps> = (props) => {
  const {
    revisedFormStore: {
      comments: { secondaryComments },
    },
    uiStore: { openModal },
  } = useStores();
  const { control } = useFormContext();

  const {
    fields,
    isLoading,
    handleChangeRows,
    handleFocusTrackChange,
    handleOpenTrackClick,
    handleAddTrackClick,
    handleAddExistingTrack,
    handleDeleteClick,
  } = useTracks();
  useTrackNotificationParams(fields as TracksForm[], handleOpenTrackClick);
  const { isReadOnly, isAdmin } = usePrimaryFormState();

  const getRowComments = (track: TracksForm) => secondaryComments.filter(({ releaseTrackId }) => releaseTrackId === track.id);

  const columns = useMemo(
    () =>
      getColumns({
        onEditClick: handleOpenTrackClick,
        onDeleteClick: !isAdmin ? handleDeleteClick : void 0,
        onFocusTrackClick: handleFocusTrackChange,
        getRowComments,
        isReadOnly,
        isAdmin,
      }),
    [handleOpenTrackClick, handleDeleteClick, handleFocusTrackChange],
  );

  const handleBulkUploadClick = () => {
    openModal({
      component: (modalProps) => <AddBulkTracks {...modalProps} />,
    });
  };

  const tracksCount = Boolean(fields.length) ? fields.length : null;
  const tracksMaxLimit = tracksCount === TRACKS_MAX_LIMIT;

  return (
    <>
      <StepFlowController {...props}>
        {isAdmin ? (
          <ReleaseAlert
            title={`If the Add Track(s) step in the sidebar is yellow, there is a comment added to it.\nPlease check the comments on the tracks by opening the track page.`}
          />
        ) : null}
        {!isReadOnly ? (
          <ReleaseAlert title="You can use faster methods to add tracks.">
            <FormBlockHeaderStyled height="100%">
              <ConditionalTooltip title={`You can add up to ${TRACKS_MAX_LIMIT} items.`} isTooltipVisible={tracksMaxLimit}>
                <Button
                  variant="link"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleAddExistingTrack}
                  disabled={tracksMaxLimit}
                >
                  Add Existing Track
                </Button>
              </ConditionalTooltip>
              <Divider orientation="vertical" />
              <ConditionalTooltip title={`You can add up to ${TRACKS_MAX_LIMIT} items.`} isTooltipVisible={tracksMaxLimit}>
                <Button
                  variant="link"
                  size="small"
                  startIcon={<UploadIcon />}
                  disabled={tracksMaxLimit}
                  onClick={handleBulkUploadClick}
                >
                  Bulk Upload
                </Button>
              </ConditionalTooltip>
            </FormBlockHeaderStyled>
          </ReleaseAlert>
        ) : null}
        <ReleaseTableListBlock
          title={<LabelWithAsterisk>Track(s)</LabelWithAsterisk>}
          hintText={
            !isAdmin
              ? "You can change the order of the tracks by dragging a row and dropping it in the place of another row."
              : void 0
          }
          itemsCount={tracksCount}
          maxLimit={TRACKS_MAX_LIMIT}
          onAddClick={handleAddTrackClick}
          isLoading={isLoading}
          addBtnText="Add New Track"
          hideActionBtn={isReadOnly}
        >
          <DndTableGrid
            rows={fields}
            columns={columns}
            loading={isLoading}
            onOrderChange={handleChangeRows}
            isDragEnabled={!isReadOnly}
            slots={{
              noRowsOverlay: NoData,
            }}
          />
        </ReleaseTableListBlock>
        <BlockContainer>
          <FormBlock>
            <Grid container gap={FormBlockGridGap}>
              <FormGridFieldItem
                label={FORM_LABELS[ApiKeyFields.marketing_info]}
                helperText={HELPER_TEXT[ApiKeyFields.marketing_info]}
                fieldName={ApiKeyFields.marketing_info}
                isOptionalField
              >
                {(field) => <TextAreaField {...field} control={control} maxLength={MARKETING_COPY_MAX_LENGTH} rows={5} />}
              </FormGridFieldItem>
            </Grid>
          </FormBlock>
        </BlockContainer>
      </StepFlowController>
      {!isAdmin ? <HelpAccordionText /> : null}
    </>
  );
};

export default observer(Tracks);
