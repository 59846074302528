import { axiosInstance } from "services";
import { ReleaseDateCounterResponse } from "types";

export function getReleaseDateCounter(userId?: string): Promise<ReleaseDateCounterResponse> {
  return axiosInstance.get(`/users/${userId ?? "current"}/release-date-counters`);
}

export function getGeneralReleaseDateCounter(): Promise<ReleaseDateCounterResponse> {
  return axiosInstance.get("/release-date-counters");
}
