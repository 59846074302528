import { ApiKeyFields } from "./apiKeyFields";

export enum Thresholds {
  SPOTIFY_ENTRY_THRESHOLD_EN = "SPOTIFY_ENTRY_THRESHOLD_EN",
  SPOTIFY_EMERGING_THRESHOLD_EN = "SPOTIFY_EMERGING_THRESHOLD_EN",
  SPOTIFY_ESTABLISHED_THRESHOLD_EN = "SPOTIFY_ESTABLISHED_THRESHOLD_EN",
  SPOTIFY_ENTRY_THRESHOLD_OTHER = "SPOTIFY_ENTRY_THRESHOLD_OTHER",
  SPOTIFY_EMERGING_THRESHOLD_OTHER = "SPOTIFY_EMERGING_THRESHOLD_OTHER",
  SPOTIFY_ESTABLISHED_THRESHOLD_OTHER = "SPOTIFY_ESTABLISHED_THRESHOLD_OTHER",
  RELEASE_DATE_ENTRY_MAX_NUM = "RELEASE_DATE_ENTRY_MAX_NUM",
  RELEASE_DATE_EMERGING_MAX_NUM = "RELEASE_DATE_EMERGING_MAX_NUM",
  RELEASE_DATE_ESTABLISHED_MAX_NUM = "RELEASE_DATE_ESTABLISHED_MAX_NUM",
}

export type Threshold = {
  [ApiKeyFields.name]: keyof Thresholds;
  [ApiKeyFields.value]: number;
  [ApiKeyFields.createdAt]?: string;
  [ApiKeyFields.updatedAt]?: string;
};

export type ThresholdResponse = {
  [ApiKeyFields.settings]: Threshold[];
};
