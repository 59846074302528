import { useCallback } from "react";
import { FieldValues, useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import findIndex from "lodash/findIndex";
import removeElement from "lodash/remove";
import { useSnackbar } from "notistack";
import { useStores } from "stores";
import * as zod from "zod";

import Typography from "@mui/material/Typography";

import { CheckboxField, ConfirmModal, DndOrderIndex } from "components";
import { FormGridItem } from "components/FormComponents/FormGridItems";
import { handleArtistsError } from "components/ReleaseForm/helpers";
import CopyEntity from "components/ReleaseForm/Shared/CopyEntity";
import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { releaseGeneralArtistService, revisedFormService } from "services";
import {
  ApiKeyFields,
  PrimaryFormNames,
  ReleaseArtists,
  ReleaseArtistsForm,
  SecondaryFormId,
  SecondaryFormNames,
  SecondaryPrimaryFormId,
} from "types";
import { replaceEmptyAndUndefinedWithNull } from "utils";

import AddEditArtist from "./AddEditArtist";
import { FORM_ARRAY_KEY } from "./constants";

export const schema = zodResolver(
  zod.object({
    [ApiKeyFields.value]: zod.union([zod.string(), zod.number()]),
    [ApiKeyFields.isFeatured]: zod.boolean().nullable(),
  }),
);

export const useArtists = () => {
  const {
    revisedFormStore: {
      comments: { filterSecondaryComments },
    },
    uiStore: { openModal, setOverlayLoading, resetLoading },
  } = useStores();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const { enqueueSnackbar } = useSnackbar();

  const form = useFormContext<{ releaseArtists: ReleaseArtistsForm[] }>();
  const { control, setValue, watch } = form;
  const { fields } = useFieldArray({
    control,
    name: FORM_ARRAY_KEY,
    keyName: "_id",
  });

  const handleArtistsArrayChange = (data: ReleaseArtistsForm) => {
    const index = findIndex(fields, { id: data.id });
    const newArray = index !== -1 ? [...fields.slice(0, index), data, ...fields.slice(index + 1)] : [...fields, data];
    setValue(FORM_ARRAY_KEY, newArray, { shouldDirty: false });
  };

  const handleAddArtist = () => {
    openModal({
      component: (modalProps) => <AddEditArtist {...modalProps} successCallback={handleArtistsArrayChange} />,
    });
  };

  const handleEditArtist = (record: ReleaseArtistsForm) => {
    openModal({
      component: (modalProps) => <AddEditArtist {...modalProps} record={record} successCallback={handleArtistsArrayChange} />,
    });
  };

  const handleDeleteArtist = async (record: ReleaseArtists, closeModal: () => void) => {
    try {
      setOverlayLoading(true);
      const requestParams: SecondaryFormId = {
        userId,
        primaryFormName: PrimaryFormNames.Releases,
        secondaryFormName: SecondaryFormNames.ReleaseArtists,
        primaryFormId: releaseId!,
        secondaryFormId: record.id as string,
      };
      await revisedFormService.deleteSecondaryFormId(requestParams);
      filterSecondaryComments(({ releaseArtistId }) => releaseArtistId !== record.id);
      closeModal();
      const updatedArray = removeElement([...fields], (field) => field.id !== record.id);
      setValue(FORM_ARRAY_KEY, updatedArray, { shouldDirty: false });
    } finally {
      resetLoading();
    }
  };

  const handleDeleteClick = (record: ReleaseArtists) => {
    openModal({
      component: (modalProps) => (
        <ConfirmModal
          {...modalProps}
          title="Remove the artist?"
          text={
            <Typography variant="body1">
              Remove <b>{record.artistName}</b> from the list. Please note, all added information will be lost.
            </Typography>
          }
          onConfirmClick={() => handleDeleteArtist(record, modalProps.closeModal)}
        />
      ),
    });
  };

  const handleChangeRows = useCallback(
    async (rows: ReleaseArtists[], { afterId, currentItemId }: DndOrderIndex) => {
      const prevValues = [...watch(FORM_ARRAY_KEY)];
      try {
        setValue(FORM_ARRAY_KEY, rows, { shouldDirty: false });
        const requestParams: SecondaryFormId = {
          userId,
          primaryFormName: PrimaryFormNames.Releases,
          secondaryFormName: SecondaryFormNames.ReleaseArtists,
          primaryFormId: releaseId!,
          secondaryFormId: currentItemId,
        };
        await revisedFormService.reorderSecondaryForm(requestParams, { afterId });
      } catch {
        setValue(FORM_ARRAY_KEY, prevValues, { shouldDirty: false });
      }
    },
    [setValue],
  );

  const fetchArtist = async ({ offset, limit }: { offset: number; limit: number }) => {
    const response = await releaseGeneralArtistService.getGeneralArtistsList({
      release_general_artist_offset: offset,
      release_general_artist_limit: limit,
    });
    return response.releaseGeneralArtists;
  };

  const copyArtist = useCallback(
    async ({ value, isFeatured }: FieldValues, options: ReleaseArtists[]) => {
      try {
        setOverlayLoading(true);
        const findRecord = options.find(({ id }) => id === value);
        if (findRecord) {
          const requestParams: SecondaryPrimaryFormId = {
            userId,
            [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
            [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseArtists,
            [ApiKeyFields.primaryFormId]: releaseId!,
          };
          const { revisedForm } = await revisedFormService.postSecondaryForm(
            requestParams,
            replaceEmptyAndUndefinedWithNull({ ...findRecord, [ApiKeyFields.releaseId]: releaseId, isFeatured }),
          );
          handleArtistsArrayChange(revisedForm);
        }
      } catch (error) {
        handleArtistsError(error as AxiosApiError, enqueueSnackbar);
        throw error;
      } finally {
        resetLoading();
      }
    },
    [fields],
  );

  const handleCopyArtist = () => {
    openModal({
      component: (modalProps) => (
        <CopyEntity
          {...modalProps}
          fetchFunction={fetchArtist}
          mapFunction={(record) => ({ label: record.artistName!, value: record.id! })}
          modalTitle="Copy Artist"
          inputLabel="Select Artist"
          onSubmitCb={copyArtist}
          defaultValues={{ [ApiKeyFields.isFeatured]: null }}
          validationSchema={schema}
        >
          {({ control }) => (
            <FormGridItem label="Is this a featured artist?" isOptionalField>
              <CheckboxField
                control={control}
                fieldName={ApiKeyFields.isFeatured}
                label={
                  <Typography variant="caption" color="text.label">
                    By default this artist is primary. Check here if the artist is featured.
                  </Typography>
                }
              />
            </FormGridItem>
          )}
        </CopyEntity>
      ),
      fullWidth: true,
    });
  };

  return {
    fields,
    handleAddArtist,
    handleEditArtist,
    handleDeleteClick,
    handleChangeRows,
    handleCopyArtist,
  };
};
