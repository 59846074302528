import { FC, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { observer } from "mobx-react";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { FormBlockGridGap, InfiniteSelectField, Modal } from "components";
import { FormGridFieldItem } from "components/FormComponents/FormGridItems";
import { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import {
  ApiKeyFields,
  PrimaryFormNames,
  ReleaseTrackWorksForm,
  SecondaryFormNames,
  TertiaryFormId,
  TertiaryFormNames,
  TertiarySecondaryFormId,
} from "types";

import { defaultValues, FORM_LABELS } from "./constants";
import { SelectWorkProps } from "./types";
import { schema } from "./validation";

const SelectWork: FC<SelectWorkProps> = ({ closeModal, track, record, successCallback }) => {
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const {
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();

  const form = useForm<ReleaseTrackWorksForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = form;

  const fetchWorks = useCallback(
    async ({ offset, limit }: { offset: number; limit: number }) => {
      const response = await revisedFormService.retrieveReleaseWorksOptions(
        { userId, primaryFormName: PrimaryFormNames.Releases, primaryFormId: releaseId! },
        { offset, limit, not_release_track_id: track.id!, order_by_field_name: "title" },
      );
      return response.revisedForms;
    },
    [track],
  );

  const onSubmit = handleSubmit(async (formData) => {
    if (!track.id || !track.releaseId) return;
    try {
      setOverlayLoading(true);
      const requestParams: TertiarySecondaryFormId = {
        userId,
        [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
        [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseTracks,
        [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleaseTrackWorks,
        [ApiKeyFields.primaryFormId]: releaseId!,
        [ApiKeyFields.secondaryFormId]: track.id,
      };
      const {
        revisedForm: { id },
      } = await revisedFormService.postTertiaryForm(requestParams, formData);
      const getRequestParams: TertiaryFormId = { ...requestParams, [ApiKeyFields.tertiaryFormId]: id! };
      const { revisedForm } = await revisedFormService.getTertiaryFormId(getRequestParams);
      successCallback?.(revisedForm);
      closeModal();
    } finally {
      resetLoading();
    }
  });

  return (
    <FormProvider {...form}>
      <Modal.ModalTitle title="Select the Related Composition" closeModal={closeModal} />
      <Modal.ModalContent dividers>
        <Grid container gap={FormBlockGridGap}>
          <FormGridFieldItem fieldName={ApiKeyFields.releaseWorkId} label={FORM_LABELS[ApiKeyFields.releaseWorkId]}>
            {(field) => (
              <InfiniteSelectField
                {...field}
                control={control}
                fetchFunction={fetchWorks}
                mapFunction={({ title, id }) => ({ label: title!, value: id! })}
                disableErrorMessage
              />
            )}
          </FormGridFieldItem>
        </Grid>
      </Modal.ModalContent>
      <Modal.ModalActions>
        <Button size="small" variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="small" variant="contained" disabled={!isValid || !isDirty} onClick={onSubmit}>
          {record ? "Edit" : "Add"}
        </Button>
      </Modal.ModalActions>
    </FormProvider>
  );
};

export default observer(SelectWork);
