import React, { createContext, useEffect, useState } from "react";
import { useSettings } from "hooks";
import type { ReleaseDateCounter, Threshold } from "types";

import { Thresholds } from "types";

interface ReleaseDateContextProps {
  settings: Threshold[];
  slots: ReleaseDateCounter[];
}

export const ReleaseDateContext = createContext<ReleaseDateContextProps>({ settings: [], slots: [] });

interface ReleaseDateProviderProps {
  slots: ReleaseDateCounter[];
  children: React.ReactNode;
}

export const ReleaseDateProvider: React.FC<ReleaseDateProviderProps> = ({ slots, children }) => {
  const [settings, setSettings] = useState<Threshold[]>([]);
  const { retrieveSettings } = useSettings();

  const getReleaseSettings = async () => {
    try {
      const settingsArray = await retrieveSettings();
      const filteredSettings = settingsArray.filter(({ name }) =>
        [
          Thresholds.RELEASE_DATE_ENTRY_MAX_NUM,
          Thresholds.RELEASE_DATE_EMERGING_MAX_NUM,
          Thresholds.RELEASE_DATE_ESTABLISHED_MAX_NUM,
        ].includes(name as Thresholds),
      );
      setSettings(filteredSettings);
    } catch {
      setSettings([]);
    }
  };

  useEffect(() => {
    getReleaseSettings();
  }, []);

  return <ReleaseDateContext.Provider value={{ slots, settings }}>{children}</ReleaseDateContext.Provider>;
};
