import dayjs from "dayjs";

import { GridSortModel } from "@mui/x-data-grid";

import { AccountsFilters, ProcessedFilters } from "pages/Admin/Accounts/Filters/types";
import { ApiKeyFields, DistributionTier } from "types";

export const processFilters = (filters?: AccountsFilters, isPublishing?: boolean) => {
  if (!filters) {
    return {};
  }

  const processedFilters: ProcessedFilters = {};

  if (!isPublishing) {
    if (filters[ApiKeyFields.user_distribution_tiers]?.length) {
      processedFilters[ApiKeyFields.user_distribution_tiers] = filters[ApiKeyFields.user_distribution_tiers];
    } else {
      processedFilters[ApiKeyFields.user_distribution_tiers] = [
        DistributionTier.Emerging,
        DistributionTier.Entry,
        DistributionTier.Established,
      ];
    }
  }

  if (filters[ApiKeyFields.artist_name]) {
    processedFilters[ApiKeyFields.artist_name] = filters[ApiKeyFields.artist_name];
  }

  if (filters[ApiKeyFields.songwriter_name]) {
    processedFilters[ApiKeyFields.songwriter_name] = filters[ApiKeyFields.songwriter_name];
  }

  if (filters[ApiKeyFields.user_email]) {
    processedFilters[ApiKeyFields.user_email] = filters[ApiKeyFields.user_email];
  }

  const schedules: DateRangeValue | null = filters[ApiKeyFields.distribution_contract_ends];
  if (schedules) {
    const [from, to] = schedules;
    processedFilters[ApiKeyFields.distribution_contract_cs_ends_at_from] = from ? dayjs(from).format("YYYY-MM-DD") : null;
    processedFilters[ApiKeyFields.distribution_contract_cs_ends_at_to] = to ? dayjs(to).format("YYYY-MM-DD") : null;
  }

  return processedFilters;
};

export const getSortingData = (sortModel: GridSortModel) => {
  const defaultSortData = {
    user_order_by_field_name: "created_at",
    user_order: "desc",
  };
  if (!sortModel.length) {
    return defaultSortData;
  }
  const { field, sort } = sortModel[0];
  const sortOrder: string = sort ?? "desc";
  if ([ApiKeyFields.email, ApiKeyFields.distributionTier].includes(field as ApiKeyFields)) {
    return {
      user_order_by_field_name: {
        [ApiKeyFields.email]: ApiKeyFields.email,
        [ApiKeyFields.distributionTier]: ApiKeyFields.distribution_tier,
      }[field],
      user_order: sortOrder,
    };
  }
  if ([ApiKeyFields.artistName, ApiKeyFields.songwriterName].includes(field as ApiKeyFields)) {
    return {
      artist_order_by_field_name: {
        [ApiKeyFields.artistName]: ApiKeyFields.artist_name,
        [ApiKeyFields.songwriterName]: ApiKeyFields.songwriter_name,
      }[field],
      artist_order: sortOrder,
    };
  }
  if ([ApiKeyFields.agreementExpiresAt].includes(field as ApiKeyFields)) {
    return {
      distribution_contract_order_by_field_name: "cs_ends_at",
      distribution_contract_order: sortOrder,
    };
  }
  return defaultSortData;
};
