import { languages } from "countries-list";

import { SubRouteFieldProps } from "components/FormProgressBar";
import { ApiKeyFields } from "types";

export const MARKETING_COPY_MAX_LENGTH = 500;

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.marketing_info]: "Marketing Copy (Optional)",
};

export const HELPER_TEXT = {
  [ApiKeyFields.marketing_info]:
    "A small paragraph that includes a brief artist bio, why this release is important or what you'd like listeners to take away from the track/release, artist social media statistics, and any marketing plans. This will be used to pitch your release to playlist editors. If you're pitching an EP or album, it's recommended to make the marketing copy about your focus track.",
} as Record<ApiKeyFields, string>;

export const TRACKS_MAX_LIMIT: number = 20;

export const TRACK_LANGUAGES_OPTIONS = Object.entries(languages)
  .filter(([lng]) => ["AR", "DE", "EN", "ES", "FR", "HE", "HI", "IT", "KO", "LA", "PT"].includes(lng.toUpperCase()))
  .map(([value, { name }]) => ({
    label: name,
    value: value.toUpperCase(),
  }));

export const TRACKS_SIDEBAR_FIELDS = [{ field: ApiKeyFields.marketing_info, required: false }] as SubRouteFieldProps[];
