import { useStores } from "stores";

import { settingService } from "services";
import { Threshold } from "types";

export const useSettings = () => {
  const {
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();

  const retrieveSettings = async (): Promise<Threshold[]> => {
    try {
      setOverlayLoading(true);
      const { settings } = await settingService.getSettings();
      return settings;
    } finally {
      resetLoading();
    }
  };

  const patchSettings = async (data: Threshold[], overlayText: string) => {
    try {
      setOverlayLoading(true, overlayText);
      await settingService.patchSettings(data);
    } finally {
      resetLoading();
    }
  };

  return { retrieveSettings, patchSettings };
};
