import { BrowserCacheLocation, Configuration, LogLevel, PublicClientApplication } from "@azure/msal-browser";

export const clientId = process.env.REACT_APP_B2C_CLIENT_ID as string;
export const authority = process.env.REACT_APP_B2C_AUTHORITY as string;
const knownAuthority = process.env.REACT_APP_B2C_KNOWN_AUTHORITY as string;
const knownAuthorities = [knownAuthority];

export const policyName = {
  signIn: "B2C_1A_SignIn_ConditionalAccess",
  signUp: "B2C_1_sign_up",
  resetPassword: "B2C_1_reset_password",
  changeEmail: "B2C_1A_ChangeSignInName",
};

export const authorities = {
  signIn: `${authority}/${policyName.signIn}`,
  signUp: `${authority}/${policyName.signUp}`,
  resetPassword: `${authority}/${policyName.resetPassword}`,
  changeEmail: `${authority}/${policyName.changeEmail}`,
};

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const config: Configuration = {
  auth: {
    clientId,
    authority: authorities.signIn,
    redirectUri: `${window.location.origin}`,
    knownAuthorities,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error: // eslint-disable-next-line
            console.error(message);
            return;
          case LogLevel.Info: // eslint-disable-next-line
            console.info(message);
            return;
          case LogLevel.Verbose: // eslint-disable-next-line
            console.debug(message);
            return;
          case LogLevel.Warning: // eslint-disable-next-line
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const instance = new PublicClientApplication(config);
export default instance;
