import { ApiKeyFields } from "types";

export const defaultValues = {
  [ApiKeyFields.artistName]: "",
  [ApiKeyFields.appleUrl]: null,
  [ApiKeyFields.spotifyUrl]: null,
  [ApiKeyFields.isFeatured]: null,
  newAppleArtist: null,
  newSpotifyArtist: null,
};
