import { ApiKeyFields } from "types";

export const defaultValues = {
  [ApiKeyFields.name]: "",
  [ApiKeyFields.advance]: null,
  [ApiKeyFields.email]: null,
  [ApiKeyFields.affiliation]: "",
  [ApiKeyFields.country]: "",
  [ApiKeyFields.split]: null,
};

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.name]: "Songwriter Name",
  [ApiKeyFields.advance]: "Does this songwriter have a Publishing Administration deal with Integrity/Integrated Music Rights?",
};

export const HELPERS_LABELS: { [key: string]: string } = {
  [ApiKeyFields.email]:
    "To ensure all songwriters receive their publishing royalties, we’ll need their email addresses to send the required licenses",
  [ApiKeyFields.affiliation]:
    "Performing Rights Organizations (PROs) are responsible for collecting income on behalf of the songwriter when a song is publicly performed or broadcasted. Common agencies are ASCAP, BMI, SESAC. Please check with the songwriter if you’re unsure.",
};
