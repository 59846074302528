import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomFormProvider } from "context/CustomFormContext";
import omit from "lodash/omit";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "stores";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { FormBlockGridGap, Modal } from "components";
import { useTertiaryListFormState } from "components/ReleaseForm/Hooks";
import type { ReleaseUrlParams } from "components/ReleaseForm/types";
import { revisedFormService } from "services";
import {
  ApiKeyFields,
  PrimaryFormNames,
  PublishersForm,
  SecondaryFormNames,
  TertiaryFormId,
  TertiaryFormNames,
  TertiarySecondaryFormId,
} from "types";

import { handleDuplicationError } from "../../../../../helpers";
import AddEditForm from "../../AddEditForm";

import { defaultValues, HELPER_LINKS, HELPERS_LABELS } from "./constants";
import { prepareFormData, prepareRequest } from "./helpers";
import { LinkStyled } from "./styled";
import { AddEditPublisherProps } from "./types";
import { schema } from "./validation";

const AddEditPublisher: FC<AddEditPublisherProps> = ({ closeModal, work, record, successCallback }) => {
  const {
    uiStore: { setOverlayLoading, resetLoading },
  } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const { userId = null, releaseId } = useParams<ReleaseUrlParams>();
  const formState = useTertiaryListFormState()({
    secondaryFormName: SecondaryFormNames.ReleaseWorks,
    tertiaryFormName: TertiaryFormNames.ReleasePublishers,
    secondaryFormId: work.id as string,
    tertiaryFormId: record?.id || "",
    filterCommentsCb: ({ releasePublisherId }) => releasePublisherId === record?.id,
    updateCommentCb: (tertiaryFormId, c) => {
      return (comment) =>
        comment.releasePublisherId === tertiaryFormId && comment.fieldName === c.fieldName ? { ...comment, ...c } : comment;
    },
    deleteCommentCb: (tertiaryFormId, fieldName) => {
      return (comment) => !(comment.releasePublisherId === tertiaryFormId && comment.fieldName === fieldName);
    },
  });

  const form = useForm<PublishersForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty, dirtyFields },
  } = form;

  useEffect(() => {
    record && reset(prepareFormData(record));
  }, [record]);

  const onSubmit = handleSubmit(async (formData) => {
    if (!work.id || !work.releaseId) return;
    try {
      setOverlayLoading(true);
      const form = omit(formData, [ApiKeyFields.advance]);
      const requestParams: TertiarySecondaryFormId = {
        userId,
        [ApiKeyFields.primaryFormName]: PrimaryFormNames.Releases,
        [ApiKeyFields.secondaryFormName]: SecondaryFormNames.ReleaseWorks,
        [ApiKeyFields.tertiaryFormName]: TertiaryFormNames.ReleasePublishers,
        [ApiKeyFields.primaryFormId]: releaseId!,
        [ApiKeyFields.secondaryFormId]: work.id,
      };
      if (record) {
        const patchRequestParams: TertiaryFormId = { ...requestParams, [ApiKeyFields.tertiaryFormId]: record.id! };
        const requestData = prepareRequest(form, dirtyFields);
        await revisedFormService.patchTertiaryForm(patchRequestParams, requestData);
        const { revisedForm } = await revisedFormService.getTertiaryFormId(patchRequestParams);
        successCallback?.(revisedForm);
      } else {
        const requestData = prepareRequest(form);
        const {
          revisedForm: { id },
        } = await revisedFormService.postTertiaryForm(requestParams, requestData);
        const getRequestParams: TertiaryFormId = { ...requestParams, [ApiKeyFields.tertiaryFormId]: id! };
        const { revisedForm } = await revisedFormService.getTertiaryFormId(getRequestParams);
        successCallback?.(revisedForm);
      }
      closeModal();
    } catch (error) {
      handleDuplicationError(error as AxiosApiError, enqueueSnackbar, true);
    } finally {
      resetLoading();
    }
  });

  const modalContent = (
    <FormProvider {...form}>
      <Modal.ModalTitle
        title={`${record ? "Edit" : "Add"} Publisher`}
        subTitle={
          <Typography variant="body1" color="text.label">
            If you are submitting a cover and are unsure of the publisher details, please check{" "}
            <LinkStyled rel="noreferrer" target="_blank" href={HELPER_LINKS.CCLI}>
              CCLI’s SongSelect
            </LinkStyled>{" "}
            or perform a repertory search on{" "}
            <LinkStyled href={HELPER_LINKS.ASCAP} rel="noreferrer" target="_blank">
              ASCAP
            </LinkStyled>
            ,{" "}
            <LinkStyled href={HELPER_LINKS.BMI} rel="noreferrer" target="_blank">
              BMI
            </LinkStyled>{" "}
            or the{" "}
            <LinkStyled href={HELPER_LINKS.MLC} rel="noreferrer" target="_blank">
              MLC’s website
            </LinkStyled>
            .
          </Typography>
        }
        closeModal={closeModal}
      />
      <Modal.ModalContent dividers>
        <Grid container gap={FormBlockGridGap}>
          <AddEditForm helpersOverride={HELPERS_LABELS} />
        </Grid>
      </Modal.ModalContent>
      {!formState.isReadOnly || formState.isEditable ? (
        <Modal.ModalActions>
          <Button size="small" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="small" variant="contained" disabled={!isValid || !isDirty} onClick={onSubmit}>
            {record ? "Edit" : "Add"}
          </Button>
        </Modal.ModalActions>
      ) : null}
    </FormProvider>
  );

  if (record) {
    return <CustomFormProvider {...formState}>{modalContent}</CustomFormProvider>;
  }

  return modalContent;
};

export default observer(AddEditPublisher);
