import { FacebookIcon, InstagramIcon, SpotifyIcon, TikTokIcon, XIcon, YouTubeIcon } from "assets/icons/24px";
import { DEFAULT_CURRENCY_VALUE } from "constants/options";
import { ApiKeyFields } from "types";

export const contactInformationValues = {
  [ApiKeyFields.firstName]: "",
  [ApiKeyFields.lastName]: "",
  [ApiKeyFields.phone]: "",
  [ApiKeyFields.email]: "",
};

export const aboutArtistValues = {
  [ApiKeyFields.bio]: "",
  [ApiKeyFields.referredBy]: null,
  [ApiKeyFields.websiteUrl]: null,
};

export const musicContentValues = {
  [ApiKeyFields.artistName]: "",
  [ApiKeyFields.recordingLanguage]: "",
};

export const musicServicesValues = {
  [ApiKeyFields.spotifyArtistId]: "",
  [ApiKeyFields.youtubeChannelId]: "",
  [ApiKeyFields.spotifyMonthlyListeners]: void 0,
};

export const socialMediaValues = {
  [ApiKeyFields.tiktokUrl]: null,
  [ApiKeyFields.instagramUrl]: null,
  [ApiKeyFields.facebookUrl]: null,
  [ApiKeyFields.twitterHandle]: null,
};

export const publishingValues = {
  [ApiKeyFields.songwriterName]: "",
  [ApiKeyFields.residenceCountry]: null,
  [ApiKeyFields.annualRevenue]: null,
  [ApiKeyFields.annualRevenueCurrency]: DEFAULT_CURRENCY_VALUE,
  [ApiKeyFields.usedSongs]: null,
  [ApiKeyFields.coveredSongs]: null,
  [ApiKeyFields.administratorName]: null,
  [ApiKeyFields.agreementExpiresAt]: "",
};

export const USER_DATA_FIELDS = [ApiKeyFields.firstName, ApiKeyFields.lastName];

export const MUSIC_SERVICES_FIELDS = [ApiKeyFields.spotifyArtistId, ApiKeyFields.youtubeChannelId];

type ServiceIcons = {
  [K in keyof typeof ApiKeyFields]?: React.ComponentType;
};

export const MUSIC_SERVICES_FIELD_ICONS: ServiceIcons = {
  [ApiKeyFields.spotifyArtistId]: SpotifyIcon,
  [ApiKeyFields.youtubeChannelId]: YouTubeIcon,
};

export const SOCIAL_MEDIA_FIELDS = [
  ApiKeyFields.tiktokUrl,
  ApiKeyFields.instagramUrl,
  ApiKeyFields.facebookUrl,
  ApiKeyFields.twitterHandle,
];

export const SOCIAL_MEDIA_FIELD_ICONS: ServiceIcons = {
  [ApiKeyFields.tiktokUrl]: TikTokIcon,
  [ApiKeyFields.instagramUrl]: InstagramIcon,
  [ApiKeyFields.facebookUrl]: FacebookIcon,
  [ApiKeyFields.twitterHandle]: XIcon,
};

export const FORM_LABELS: { [key: string]: string } = {
  [ApiKeyFields.firstName]: "contactInfo.firstName",
  [ApiKeyFields.lastName]: "contactInfo.lastName",
  [ApiKeyFields.email]: "contactInfo.email",
  [ApiKeyFields.phone]: "contactInfo.mobileNumber",
  [ApiKeyFields.bio]: "artistInfo.bioPrompt",
  [ApiKeyFields.websiteUrl]: "artistInfo.websiteOptional",
  [ApiKeyFields.referredBy]: "artistInfo.referredByOptional",
  [ApiKeyFields.artistName]: "artistInfo.artistName",
  [ApiKeyFields.recordingLanguage]: "artistInfo.primaryLanguage",
  [ApiKeyFields.spotifyArtistId]: "artistInfo.spotifyID",
  [ApiKeyFields.youtubeChannelId]: "socialMedia.youtubeURL",
  [ApiKeyFields.tiktokUrl]: "socialMedia.tikTokURL",
  [ApiKeyFields.instagramUrl]: "socialMedia.instagramURL",
  [ApiKeyFields.facebookUrl]: "socialMedia.facebookURL",
  [ApiKeyFields.twitterHandle]: "socialMedia.twitterHandle",
  [ApiKeyFields.songwriterName]: "publishingInfo.songwriterName",
  [ApiKeyFields.residenceCountry]: "publishingInfo.countryOfResidence",
  [ApiKeyFields.annualRevenue]: "publishingInfo.annualRevenue",
  [ApiKeyFields.usedSongs]: "publishingInfo.songsUsedInServices",
  [ApiKeyFields.coveredSongs]: "publishingInfo.artistsThatCovering",
  [ApiKeyFields.agreementExpiresAt]: "agreementStatus.endedOrSetToEnd",
  [ApiKeyFields.administratorName]: "administration.priorAdministratorName",
  [ApiKeyFields.messageForTheIntegrityTeam]: "label.tellUsYourStory",
  [ApiKeyFields.labelName]: "label.labelName",
  [ApiKeyFields.labelWebsite]: "label.labelWebsiteOptional",
  [ApiKeyFields.annualRevenueCurrency]: "",
  [ApiKeyFields.hasMultipleChurches]: "publishingInfo.churchUsageQuery",
  [ApiKeyFields.haveCoveredSongs]: "publishingInfo.songsCoveredByOtherArtists",
  [ApiKeyFields.hasAdministrator]: "publishingInfo.hasCatalogAdmin",
  [ApiKeyFields.spotifyMonthlyListeners]: "publishingInfo.spotifyMonthlyListeners",
  [ApiKeyFields.distributionTier]: "",
  [ApiKeyFields.publishingTier]: "",
};

export const HELPER_TEXT = {
  [ApiKeyFields.email]: "contactInfo.mainEmailNote",
  [ApiKeyFields.phone]: "E.g. “+1 401 XXX XXXX”",
  [ApiKeyFields.referredBy]: "contactInfo.recommendationProvider",
  [ApiKeyFields.websiteUrl]: "E.g. 'https://happyartist.com'",
  [ApiKeyFields.tiktokUrl]: "E.g. “https://tiktok.com/@username”",
  [ApiKeyFields.facebookUrl]: `E.g. “https://www.facebook.com/username”`,
  [ApiKeyFields.instagramUrl]: "E.g. “https://www.instagram.com/username”",
  [ApiKeyFields.twitterHandle]: `E.g. “https://x.com/username”`,
  [ApiKeyFields.artistName]: "helpText.artistNameOnPlatforms",
  [ApiKeyFields.recordingLanguage]: "helpText.primaryRecordingLanguage",
  [ApiKeyFields.spotifyArtistId]: "helpText.spotifyProfileURL",
  [ApiKeyFields.youtubeChannelId]: "helpText.youtubeChannelIDInfo",
  [ApiKeyFields.annualRevenue]: "publishingInfo.annualRevenue.helperText",
} as Record<ApiKeyFields, string>;

export const MAX_LENGTH: { [key: string]: number } = {
  [ApiKeyFields.bio]: 1000,
  [ApiKeyFields.usedSongs]: 250,
  [ApiKeyFields.coveredSongs]: 250,
};

export const defaultDistributionValues = {
  userRole: null,
  ...contactInformationValues,
  ...aboutArtistValues,
  ...musicContentValues,
  ...musicServicesValues,
  ...socialMediaValues,
};

export const defaultPublishingValues = {
  userRole: null,
  ...contactInformationValues,
  ...publishingValues,
};

export const defaultDPValues = {
  userRole: null,
  ...contactInformationValues,
  ...aboutArtistValues,
  ...musicContentValues,
  ...musicServicesValues,
  ...socialMediaValues,
  ...publishingValues,
};

export const EXTRACT_SPOTIFY_ARTIST_ID = /spotify\.com\/artist\/([a-zA-Z0-9]+)/;
export const EXTRACT_YOUTUBE_CHANNEL_ID = /youtube\.com\/(@[\w\d_-]+)/;
