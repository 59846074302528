const replaceIdInString = (baseUrl: string, id?: string): string => {
  return id ? baseUrl.replace(":id", id) : baseUrl;
};

const replaceParamsInString = (baseUrl: string, params?: Record<string, string | undefined>): string => {
  if (!params) return baseUrl;
  let result = baseUrl;
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      result = result.replace(`:${key}`, value);
    }
  });
  return result;
};

const createRoute = (base: string, path: string) => `${base}${path}`;

export const ROUTES = {
  // Base Routes
  ROOT: "/",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  CHANGE_EMAIL: "/change-email",
  FORM: "/form",
  DISTR_INFO: "/distr-info",
  DISTR_LEGAL: "/distr-legal",
  QUALIFICATION: "/qualification",
  DISTRIBUTION: "/distribution",
  DAP: "/dap",
  DISTRIBUTION_AND_PUBLISHING: "/distribution-and-publishing",
  PUBLISHING: "/publishing",
  QUALIFIED: "/qualified",
  IN_REVIEW: "/in-review",
  COLLABORATION_STATUS: "/collaboration-status",
  ADMINS: "/admins",
  APPLICATIONS: "/applications",
  ACCOUNTS: "/accounts",
  RELEASE: "/release",
  RELEASES: "/releases",
  INVITE: "/invite",
  LABEL: "/label",
  PUBLISHING_ADMINISTRATION: "/publishing-administration",
  APPLICATION_SETTINGS: "/application-settings",
  SETTINGS: "/settings",
  SAVING: "/saving",
  SAMPLES: "/samples",
  NOT_EXIST: "/not-exist",
  COMING_SOON: "/coming-soon",
  COMING_SOON_PUBLISHING: "/coming-soon/publishing",
  CONTRACT_REVIEW: "/contract-review",
  CONTRACT_SIGNED: "/contract-signed",
  DASHBOARD: "/dashboard",

  // Routes with additional paths
  FORM_ROUTE: "form",

  // Dynamic Parts
  ADDRESS_ROUTE: "address",
  CATALOG_ROUTE: "catalog",
  AGREEMENT_ROUTE: "agreement",
  EXCLUSION_ROUTE: "exclusion",
  DISTRIBUTION_ROUTE: "distribution",
  CONTACT_ROUTE: "contact",
  GENRE_ROUTE: "genre",
  WORKS_ROUTE: "works",
  TRACKS_ROUTE: "tracks",
  RELEASE_DATE_ROUTE: "release-date",
  PUBLISHING_ROUTE: "publishing",
  DAP_ROUTE: "dap",
  USER_ID_PARAM: "userId",
  RELEASE_ID_PARAM: "releaseId",

  // Routes using Functions
  DISTR_INFO_ADDRESS: function () {
    return createRoute(this.DISTR_INFO, `/${this.ADDRESS_ROUTE}`);
  },
  DISTR_INFO_CATALOG: function () {
    return createRoute(this.DISTR_INFO, `/${this.CATALOG_ROUTE}`);
  },
  DISTRIBUTION_FORM: function () {
    return createRoute(this.DISTRIBUTION, `/${this.FORM_ROUTE}`);
  },
  DISTRIBUTION_AND_PUBLISHING_FORM: function () {
    return createRoute(this.DISTRIBUTION_AND_PUBLISHING, `/${this.FORM_ROUTE}`);
  },
  PUBLISHING_FORM: function () {
    return createRoute(this.PUBLISHING, `/${this.FORM_ROUTE}`);
  },
  INVITE_ADMIN: function () {
    return createRoute(this.ADMINS, this.INVITE);
  },
  APPLICATIONS_PUBLISHING_ID: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.PUBLISHING}/:id`), id);
  },
  APPLICATIONS_DISTRIBUTION_ID: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTRIBUTION}/:id`), id);
  },
  APPLICATIONS_DISTR_INFO_ID: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTR_INFO}/:id`), id);
  },
  APPLICATIONS_DISTR_INFO_ID_ADDRESS: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTR_INFO}/:id/${this.ADDRESS_ROUTE}`), id);
  },
  APPLICATIONS_DISTR_INFO_ID_CONTACT: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTR_INFO}/:id/${this.CONTACT_ROUTE}`), id);
  },
  APPLICATIONS_DISTR_INFO_ID_CATALOG: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTR_INFO}/:id/${this.CATALOG_ROUTE}`), id);
  },
  APPLICATIONS_DISTR_INFO_ID_AGREEMENT: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTR_INFO}/:id/${this.AGREEMENT_ROUTE}`), id);
  },
  APPLICATIONS_DISTRIBUTION_AND_PUBLISHING_ID: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTRIBUTION_AND_PUBLISHING}/:id`), id);
  },
  APPLICATIONS_DISTR_LEGAL_ID: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTR_LEGAL}/:id`), id);
  },
  APPLICATIONS_DISTR_LEGAL_ID_ADDRESS: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTR_LEGAL}/:id/${this.ADDRESS_ROUTE}`), id);
  },
  APPLICATIONS_DISTR_LEGAL_ID_CONTACT: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTR_LEGAL}/:id/${this.CONTACT_ROUTE}`), id);
  },
  APPLICATIONS_DISTR_LEGAL_ID_CATALOG: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTR_LEGAL}/:id/${this.CATALOG_ROUTE}`), id);
  },
  APPLICATIONS_DISTR_LEGAL_ID_AGREEMENT: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTR_LEGAL}/:id/${this.AGREEMENT_ROUTE}`), id);
  },
  APPLICATIONS_DISTR_LEGAL_ID_DISTRIBUTION: function (id?: string) {
    return replaceIdInString(createRoute(this.APPLICATIONS, `${this.DISTR_LEGAL}/:id/${this.DISTRIBUTION_ROUTE}`), id);
  },
  ACCOUNTS_PUBLISHING: function () {
    return createRoute(this.ACCOUNTS, `/${this.PUBLISHING_ROUTE}`);
  },
  ACCOUNTS_DAP: function () {
    return createRoute(this.ACCOUNTS, `/${this.DAP_ROUTE}`);
  },
  ACCOUNTS_ID: function (id?: string) {
    return replaceIdInString(createRoute(this.ACCOUNTS, `/:id`), id);
  },
  ACCOUNTS_ID_ADDRESS: function (id?: string) {
    return replaceIdInString(createRoute(this.ACCOUNTS, `/:id/${this.ADDRESS_ROUTE}`), id);
  },
  ACCOUNTS_ID_CONTACT: function (id?: string) {
    return replaceIdInString(createRoute(this.ACCOUNTS, `/:id/${this.CONTACT_ROUTE}`), id);
  },
  ACCOUNTS_ID_CATALOG: function (id?: string) {
    return replaceIdInString(createRoute(this.ACCOUNTS, `/:id/${this.CATALOG_ROUTE}`), id);
  },
  ACCOUNTS_ID_AGREEMENT: function (id?: string) {
    return replaceIdInString(createRoute(this.ACCOUNTS, `/:id/${this.AGREEMENT_ROUTE}`), id);
  },
  ACCOUNTS_ID_DISTRIBUTION: function (id?: string) {
    return replaceIdInString(createRoute(this.ACCOUNTS, `/:id/${this.DISTRIBUTION_ROUTE}`), id);
  },
  RELEASE_CLIENT_ID: "/release-id",
  CLIENT_DRAFT_RELEASE_ID: function (releaseId?: string) {
    return replaceParamsInString(createRoute(this.RELEASE_CLIENT_ID, `/:${this.RELEASE_ID_PARAM}`), { releaseId });
  },
  CLIENT_DRAFT_RELEASE_ID_GENRE: function (releaseId?: string) {
    return replaceParamsInString(createRoute(this.RELEASE_CLIENT_ID, `/:${this.RELEASE_ID_PARAM}/${this.GENRE_ROUTE}`), {
      releaseId,
    });
  },
  CLIENT_DRAFT_RELEASE_ID_WORKS: function (releaseId?: string) {
    return replaceParamsInString(createRoute(this.RELEASE_CLIENT_ID, `/:${this.RELEASE_ID_PARAM}/${this.WORKS_ROUTE}`), {
      releaseId,
    });
  },
  CLIENT_DRAFT_RELEASE_ID_TRACKS: function (releaseId?: string) {
    return replaceParamsInString(createRoute(this.RELEASE_CLIENT_ID, `/:${this.RELEASE_ID_PARAM}/${this.TRACKS_ROUTE}`), {
      releaseId,
    });
  },
  CLIENT_DRAFT_RELEASE_ID_RELEASE_DATE: function (releaseId?: string) {
    return replaceParamsInString(createRoute(this.RELEASE_CLIENT_ID, `/:${this.RELEASE_ID_PARAM}/${this.RELEASE_DATE_ROUTE}`), {
      releaseId,
    });
  },
  RELEASE_ID: function (userId?: string, releaseId?: string) {
    return replaceParamsInString(createRoute(this.RELEASES, `/:${this.USER_ID_PARAM}/:${this.RELEASE_ID_PARAM}`), {
      userId,
      releaseId,
    });
  },
  RELEASE_ID_GENRE: function (userId?: string, releaseId?: string) {
    return replaceParamsInString(
      createRoute(this.RELEASES, `/:${this.USER_ID_PARAM}/:${this.RELEASE_ID_PARAM}/${this.GENRE_ROUTE}`),
      {
        userId,
        releaseId,
      },
    );
  },
  RELEASE_ID_WORKS: function (userId?: string, releaseId?: string) {
    return replaceParamsInString(
      createRoute(this.RELEASES, `/:${this.USER_ID_PARAM}/:${this.RELEASE_ID_PARAM}/${this.WORKS_ROUTE}`),
      {
        userId,
        releaseId,
      },
    );
  },
  RELEASE_ID_TRACKS: function (userId?: string, releaseId?: string) {
    return replaceParamsInString(
      createRoute(this.RELEASES, `/:${this.USER_ID_PARAM}/:${this.RELEASE_ID_PARAM}/${this.TRACKS_ROUTE}`),
      {
        userId,
        releaseId,
      },
    );
  },
  RELEASE_ID_RELEASE_DATE: function (userId?: string, releaseId?: string) {
    return replaceParamsInString(
      createRoute(this.RELEASES, `/:${this.USER_ID_PARAM}/:${this.RELEASE_ID_PARAM}/${this.RELEASE_DATE_ROUTE}`),
      {
        userId,
        releaseId,
      },
    );
  },
  INVITE_DISTRIBUTION: function () {
    return createRoute(this.INVITE, `/${this.DISTRIBUTION_ROUTE}`);
  },
  INVITE_DISTRIBUTION_FORM: function () {
    return createRoute(this.INVITE, `/${this.DISTRIBUTION_ROUTE}${this.FORM}`);
  },
  INVITE_DISTRIBUTION_AND_PUBLISHING: function () {
    return createRoute(this.INVITE, this.DISTRIBUTION_AND_PUBLISHING);
  },
  INVITE_DISTRIBUTION_AND_PUBLISHING_FORM: function () {
    return createRoute(this.INVITE, `${this.DISTRIBUTION_AND_PUBLISHING}${this.FORM_ROUTE}`);
  },
  INVITE_PUBLISHING: function () {
    return createRoute(this.INVITE, `/${this.PUBLISHING_ROUTE}`);
  },
  INVITE_PUBLISHING_FORM: function () {
    return createRoute(this.INVITE, `/${this.PUBLISHING_ROUTE}${this.FORM_ROUTE}`);
  },
  DISTR_LEGAL_ADDRESS: function () {
    return createRoute(this.DISTR_LEGAL, `/${this.ADDRESS_ROUTE}`);
  },
  DISTR_LEGAL_CATALOG: function () {
    return createRoute(this.DISTR_LEGAL, `/${this.CATALOG_ROUTE}`);
  },
  DISTR_LEGAL_AGREEMENT: function () {
    return createRoute(this.DISTR_LEGAL, `/${this.AGREEMENT_ROUTE}`);
  },
  DISTR_LEGAL_DISTRIBUTION: function () {
    return createRoute(this.DISTR_LEGAL, `/${this.DISTRIBUTION_ROUTE}`);
  },
};
