import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { zodResolver } from "@hookform/resolvers/zod";
import useInfiniteLoading from "hooks/useInfiniteLoading";
import { observer } from "mobx-react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { FormBlockGridGap, Modal } from "components";
import { InfiniteSelect } from "components";
import { FormGridItem } from "components/FormComponents/FormGridItems";
import { ApiKeyFields } from "types";

import { CopySongwriterProps } from "./types";
import { schema } from "./validation";

const CopyEntity = <DataType, ValueType extends string | number>({
  fetchFunction,
  mapFunction,
  closeModal,
  onSubmitCb,
  validationSchema,
  modalTitle,
  inputLabel,
  defaultValues,
  children,
}: CopySongwriterProps<DataType, ValueType>) => {
  const { options, rawOptions, loading, hasNextPage, fetchMore } = useInfiniteLoading(fetchFunction, mapFunction, {
    initialOffset: 0,
    limit: 10,
  });

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: fetchMore,
  });

  const form = useForm({
    defaultValues: {
      ...defaultValues,
      [ApiKeyFields.value]: null,
    },
    resolver: validationSchema ? validationSchema : zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { control, handleSubmit, setValue, watch } = form;

  const onSubmit = handleSubmit(async (formData) => {
    try {
      await onSubmitCb(formData, rawOptions);
      closeModal();
    } catch {
      null;
    }
  });

  const selectedValue = watch(ApiKeyFields.value);

  return (
    <FormProvider {...form}>
      <Modal.ModalTitle title={modalTitle} closeModal={closeModal} />
      <Modal.ModalContent dividers>
        <Grid container gap={FormBlockGridGap}>
          <FormGridItem label={inputLabel} isOptionalField>
            <InfiniteSelect
              value={selectedValue}
              options={options}
              loading={loading}
              hasNextPage={hasNextPage}
              sentryRef={sentryRef}
              onChange={(opt) => setValue(ApiKeyFields.value, opt)}
            />
          </FormGridItem>
          {children ? children({ control }) : null}
        </Grid>
      </Modal.ModalContent>
      <Modal.ModalActions>
        <Button size="small" variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="small" variant="contained" onClick={onSubmit} disabled={!selectedValue}>
          Copy
        </Button>
      </Modal.ModalActions>
    </FormProvider>
  );
};

export default observer(CopyEntity);
