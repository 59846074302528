import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";

import { HelpAccordion } from "components/FormComponents";

const TextContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HelpAccordionText = () => {
  return (
    <HelpAccordion title="Need some help?" position="sticky">
      <TextContainerStyled>
        {[
          "A primary artist is the main artist at the track level, typically including the album artist and other key artists on the release. This role is used to denote collaborations between artists.",
          "A featured artist, on the other hand, assists in the performance of a track but is not considered a primary artist. Featured artists often contribute a verse or serve as vocalists on a track.",
          "You can change the order of the artists by dragging a row and dropping it in the place of another row.",
        ].map((text, index) => (
          <Typography variant="body1" key={index} flex={1}>
            {text}
          </Typography>
        ))}
      </TextContainerStyled>
    </HelpAccordion>
  );
};

export default HelpAccordionText;
