import * as zod from "zod";

import { MAX_LEN_TEXTAREA_ERR_MSG } from "constants/validation";
import { ApiKeyFields } from "types";

import { MARKETING_COPY_MAX_LENGTH } from "./constants";

export const marketingServiceSchema = zod.object({
  [ApiKeyFields.marketing_info]: zod.string().max(MARKETING_COPY_MAX_LENGTH, MAX_LEN_TEXTAREA_ERR_MSG).nullable().optional(),
});
