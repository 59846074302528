import { FC, Fragment, ReactElement, useContext, useMemo } from "react";
import styled from "@emotion/styled";
import { ReleaseDateContext } from "context";
import dayjs, { Dayjs } from "dayjs";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { THRESHOLDS_DATA } from "pages/Admin/Releases/ReleaseSlotSettings/ReleaseSlotModal/constants";

interface ReleaseSlotsTooltipProps {
  date: Dayjs;
  children: ReactElement;
  isAdmin?: boolean;
}

const TooltipContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ReleaseSlotsTooltip: FC<ReleaseSlotsTooltipProps> = ({ date, isAdmin, children }) => {
  const { slots, settings } = useContext(ReleaseDateContext);

  const slotData = useMemo(() => {
    const counters = slots.find(({ releaseDate }) => dayjs(releaseDate).isSame(date, "day"))?.releaseCounters;
    if (!counters?.length) {
      return [];
    }
    return counters.map(({ distributionTier, ...rest }) => {
      const threshold = THRESHOLDS_DATA.find(({ tier }) => distributionTier === tier)!;
      const maxReleasesLimit = settings.find(({ name }) => name === (threshold.fieldName as string))?.value || 0;
      return {
        ...threshold,
        maxReleasesLimit,
        ...rest,
      };
    });
  }, [date, slots, settings]);

  const { message, hoverDisabled } = useMemo(() => {
    const today = dayjs().startOf("day");
    const lastAccessibleDate = today.add(365, "day");
    const firstAvailableFriday = today.day() === 5 ? today.add(5, "week").day(5) : today.add(4, "week").day(5);

    if (
      date.isBefore(today) ||
      date.isAfter(lastAccessibleDate, "day") ||
      (!isAdmin && date.isBefore(firstAvailableFriday, "day"))
    ) {
      return { message: null, hoverDisabled: true };
    }

    if (isAdmin) {
      if (slotData.length > 0) {
        return { message: null, hoverDisabled: false };
      }
      return { message: null, hoverDisabled: date.day() !== 5 };
    }

    if (slotData.length === 0) {
      return { message: "It is industry best practice to release new music on Fridays.", hoverDisabled: false };
    }

    if (slotData.every(({ numAvailableReleases }) => numAvailableReleases === 0)) {
      return { message: "All slots for this release date have been taken. Please select another date.", hoverDisabled: false };
    }

    return { message: null, hoverDisabled: true };
  }, [date, slotData, isAdmin]);

  return (
    <Tooltip
      PopperProps={{ sx: { maxWidth: "210px" } }}
      disableHoverListener={hoverDisabled}
      title={
        message ? (
          message
        ) : (
          <TooltipContainerStyled>
            <Typography variant="caption" textAlign="center" fontWeight="700" width="100%" mb="3px">
              Available slots
            </Typography>
            <Grid container spacing={0.9} minWidth={130} maxWidth={180}>
              {slotData.map(({ label, tier, icon, numAvailableReleases, maxReleasesLimit }) => (
                <Fragment key={tier}>
                  <Grid item xs={9} display="flex" alignItems="center" gap="2px">
                    <Typography variant="caption" color="text.label" fontWeight="700">
                      {label}
                    </Typography>{" "}
                    {icon}
                  </Grid>
                  <Grid item xs={3} display="flex" justifyContent="end">
                    <Typography variant="caption" color="text.label" fontWeight="700" textAlign="center">
                      {isAdmin ? `${numAvailableReleases}/${maxReleasesLimit}` : numAvailableReleases}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </TooltipContainerStyled>
        )
      }
      placement="top"
      arrow
    >
      {children}
    </Tooltip>
  );
};

export default ReleaseSlotsTooltip;
